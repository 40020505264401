import { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { GetDateFromISOString } from "../../../api/helpers/datesHelpers";
import { FormatDecimal } from "../../../api/helpers/formatters";
import { OrdersByProduct } from "../../../api/models/orderModel";
import { Product } from "../../../api/models/productModel";
import { GetOrdersByProduct } from "../../../api/services/ordersServices";
import LabelGroup from "../../../components/LabelGroup";
import React from "react";
import { GetErrorMessage } from "../../../api/helpers/apiHelpers";
import TitleBar from "../../../components/TitleBar";

const ViewOrdersProduct = () => {
    const location = useLocation();
    const params = useParams();
    const navigate = useNavigate();

    const [isLoading, setIsLoading] = useState(true);
    const [errorMessage, setErrorMessage] = useState<React.ReactNode>();

    const [orders, setOrders] = useState<OrdersByProduct[]>([]);
    const [product, setProduct] = useState<Product>();
    const [totalQuantity, setCantidadTotal] = useState<number>(0);

    useEffect(() => {
        fetchData();
    }, []);

    const fetchData = async () => {
        const productId = parseInt(params.productId as string);

        const searchParams = new URLSearchParams(location.search);
        const status = parseInt(searchParams.get("status") as string);
        const desde = GetDateFromISOString(searchParams.get("desde") as string);
        const hasta = GetDateFromISOString(searchParams.get("hasta") as string);

        try {
            setIsLoading(true);
            const response = await GetOrdersByProduct(
                status,
                { Desde: desde, Hasta: hasta },
                productId
            );
            if (!response.success) {
                setErrorMessage(response.message);
                return;
            }

            if (response.orders.length === 0) {
                setErrorMessage("No se encontraron pedidos de este producto.");
                return;
            }

            setProduct(response.product);
            const sum = response.orders.reduce(
                (sum, item) => sum + item.quantity,
                0
            );
            setCantidadTotal(sum);

            setOrders(response.orders);
        } catch (error) {
            setErrorMessage(GetErrorMessage(error));
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <div>
            <TitleBar
                title="Pedidos del producto"
                backUrl="../products-ordered"
            ></TitleBar>
            <div className="panel">
                <div id="product" className="d-flex">
                    <div>
                        <img
                            style={{ maxWidth: "64px", maxHeight: "64px" }}
                            src={product?.thumbUrl}
                            alt={product?.name}
                        />
                    </div>
                    <div className="flex-grow-1 ps-2 ">
                        <div>{product?.name}</div>
                        <div>
                            <strong>
                                {FormatDecimal(totalQuantity, false)}
                            </strong>{" "}
                            {product?.unitOfMeasure}
                        </div>
                    </div>
                </div>
            </div>
            {orders.map((x, i) => (
                <div className="panel d-flex" key={i}>
                    <div className="d-flex justify-content-between">
                        <LabelGroup caption="Pedido">
                            <strong>{x.orderId}</strong>
                        </LabelGroup>
                        <LabelGroup caption="Fecha entrega">
                            {x.promisedDeliveryDate}
                        </LabelGroup>
                    </div>
                    <div>
                        <strong>{x.customerName}</strong>
                    </div>
                    <div className="d-flex justify-content-between">
                        <LabelGroup caption="Solicitado">
                            <strong> {x.quantity}</strong> {x.unitOfMeasure}
                        </LabelGroup>
                        <LabelGroup caption="Surtido">
                            <strong> {x.quantityFulfilled}</strong>{" "}
                            {x.unitOfMeasure}
                        </LabelGroup>
                    </div>

                    <div className="d-flex display-column"></div>
                </div>
            ))}
        </div>
    );
};

export default ViewOrdersProduct;
