import { useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Order, OrderPayment } from "../../../api/models/orderModel";
import {
  CalculateOrderBalance,
  CalculateOrderFulfilledTotal,
  GetOrderReceivable,
  GetOrderReceivableViewModel,
  UpdatePayment,
  UpdatePayments,
} from "../../../api/services/ordersServices";
import { FormatDecimal } from "../../../api/helpers/formatters";
import TitleBar from "../../../components/TitleBar";
import OrderPayments from "./OrderPayments";
import { GetErrorMessage } from "../../../api/helpers/apiHelpers";
import ErrorModal from "../../../components/ErrorModal";
import LoadingComponent from "../../../components/LoadingComponent";
import { Alert, Button } from "react-bootstrap";
import { set } from "date-fns";

const OrderReceivable = () => {
  const params = useParams();
  const navigate = useNavigate();

  const [order, setOrder] = useState<GetOrderReceivableViewModel>();

  const [errorText, setErrorText] = useState("");

  const [isLoading, setIsLoading] = useState(true);
  const [errorMessage, setErrorMessage] = useState<React.ReactNode>();

  const initializing = useRef(false); // Esto es para evitar que se ejecute el useEffect dos veces

  useEffect(() => {
    if (!initializing.current) fetchData();
  }, [initializing]);

  const fetchData = async () => {
    initializing.current = true;

    const orderId = params.orderId ? parseInt(params.orderId) : -1;
    if (orderId === -1) {
      setErrorMessage("No ha indicado la orden a pagar");
      return;
    }

    try {
      setIsLoading(true);
      setErrorMessage(undefined);
      const response = await GetOrderReceivable(orderId);
      if (!response.success) {
        setErrorMessage(response.message);
        return;
      }

      setOrder(response.order);
    } catch (error) {
      setErrorMessage(GetErrorMessage(error));
    } finally {
      setIsLoading(false);
    }
  };

  const handlePaymentChanged = (orderPayments: Array<OrderPayment>) => {
    // Valida que los pagos no excedan el total del pedido
    const saldo = calcularSaldo(orderPayments);
    if (saldo < 0) {
      setErrorText(
        "El total de los pagos no puede ser mayor al total surtido del pedido."
      );
      return;
    }

    setErrorText("");
    setOrder({ ...order!, payments: orderPayments });
  };

  const handlePagar = async () => {
    // Valida que los pagos no excedan el total del pedido
    const saldo = calcularSaldo(order?.payments as Array<OrderPayment>);
    if (saldo < 0) {
      setErrorText(
        "El total de los pagos no puede ser mayor al total surtido del pedido."
      );
      return;
    }

    setErrorText("");

    try {
      setIsLoading(true);
      setErrorMessage(undefined);

      const response = await UpdatePayments(order!.id, order!.payments);
      if (!response.success) {
        setErrorMessage(response.message);
        return;
      }

      navigate("../orders-receivable");
    } catch (error) {
      setErrorMessage(GetErrorMessage(error));
    } finally {
      setIsLoading(false);
    }
  };

  const calcularSaldo = (orderPayments: Array<OrderPayment>) => {
    const sumPayments = orderPayments.reduce((accumulator, currentObject) => {
      return accumulator + currentObject.amount;
    }, 0);
    return order!.totalFulfilled - sumPayments;
  };

  if (errorMessage)
    return (
      <ErrorModal
        errorMessage={errorMessage}
        onRetryClick={!order ? fetchData : undefined}
        onOkClick={() => {
          if (!order) navigate(-1);
          else setErrorMessage(undefined);
        }}
      />
    );

  if (isLoading || !order) return <LoadingComponent />;

  return (
    <div>
      <TitleBar title="Registrar cobros" backUrl="../orders-receivable" />

      <div>
        <div>
          Pedido <strong>{order.id}</strong>
        </div>
        <div>
          <strong>{order.customerName}</strong>
        </div>
        <div className="d-flex justify-content-between">
          <div>
            Total: <strong>{FormatDecimal(order.totalFulfilled, true)}</strong>
          </div>
          <div>
            Saldo: <strong>{FormatDecimal(order.balance, true)}</strong>
          </div>
        </div>
      </div>

      <div className="panel">
        <OrderPayments
          orderTotal={order.totalFulfilled}
          orderPayments={order.payments}
          onChange={handlePaymentChanged}
        />

        {errorText && (
          <Alert className="mt-4 mb-2 w-100" variant="danger">
            {errorText}
          </Alert>
        )}

        <Button
          disabled={errorText !== ""}
          variant="success"
          onClick={handlePagar}
        >
          Entregar
        </Button>
      </div>
    </div>
  );
};

export default OrderReceivable;
