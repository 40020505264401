import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { FormatDate } from "../../api/helpers/formatters";
import { Coupon, eCouponStatus } from "../../api/models/couponModel";
import { CancelCoupon, GetCoupons } from "../../api/services/couponServices";
import SearchEntry from "../../components/SearchEntry";
import Tabs from "../../components/Tab";
import LoadingComponent from "../../components/LoadingComponent";
import ErrorModal from "../../components/ErrorModal";
import { useSession } from "../../api/context/SessionContext";

const CouponsListPage = () => {
    const navigate = useNavigate();
    const session = useSession();

    const [coupons, setCoupons] = useState<Coupon[]>([]);

    const [selectedStatus, setSelectedStatus] = useState<number>(
        eCouponStatus.Active
    );

    const [searchText, setSearchText] = useState<string | undefined>();
    const [isLoading, setIsLoading] = useState(false);
    const [loadingError, setLoadingError] = useState<React.ReactNode>();

    useEffect(() => {
        // Valida que tenga acceso a esta opción
        if (
            !session.isLoggedIn() ||
            !session.hasDoor("db704418-3e0c-46b7-9062-7faac0cf161c")
        ) {
            alert("No tiene permiso para ingresar a esta sección");
            navigate("../");
            return;
        }

        fetchData();
    }, []);

    const fetchData = async (status: eCouponStatus = selectedStatus) => {
        try {
            setIsLoading(true);
            setLoadingError(undefined);

            let result = await GetCoupons(status);

            setCoupons(result);
        } catch (error) {
            let message = error;
            if (error instanceof Error) message = error.message;
            setLoadingError(
                <p>
                    Ha ocurrido un error al cargar los datos. <br /> Reintente
                    más tarde.
                </p>
            );

            console.log(message, error);
        } finally {
            setIsLoading(false);
        }
    };

    const getCoupons = () => {
        let ds = [...coupons];

        if (selectedStatus !== eCouponStatus.None)
            ds = ds.filter((x) => x.status === selectedStatus);

        if (searchText) {
            let searchTextLower = searchText.toLocaleLowerCase();
            ds = ds.filter(
                (x) =>
                    x.description
                        .toLocaleLowerCase()
                        .includes(searchTextLower) ||
                    x.code.toLocaleLowerCase().includes(searchTextLower) ||
                    x.orderIdApplied?.toString().includes(searchTextLower)
            );
        }

        return ds.sort((a, b) =>
            a.code < b.code ? -1 : a.code > b.code ? 1 : 0
        );
    };

    const getStatusPill = (status: eCouponStatus) => {
        switch (status) {
            case eCouponStatus.Active:
                return (
                    <span className="badge rounded-pill bg-primary">
                        Activo
                    </span>
                );
            case eCouponStatus.Applied:
                return (
                    <span className="badge rounded-pill bg-success">
                        Aplicado
                    </span>
                );
            case eCouponStatus.Canceled:
                return (
                    <span className="badge rounded-pill bg-danger">
                        Cancelado
                    </span>
                );
            case eCouponStatus.Expired:
                return (
                    <span className="badge rounded-pill bg-warning">
                        Vencido
                    </span>
                );
        }
    };

    const cancelCoupon = async (coupon: Coupon) => {
        if (!window.confirm("¿Está seguro que desea cancelar el cupón?"))
            return;
        try {
            setIsLoading(true);
            setLoadingError(undefined);

            let result = await CancelCoupon(coupon.id);
            if (result.success) {
                await fetchData();
            } else {
                setLoadingError(result.message);
            }
        } catch (error) {
            let message = error;
            if (error instanceof Error) message = error.message;
            setLoadingError(
                <p>
                    Ha ocurrido un error al cargar los datos. <br /> Reintente
                    más tarde.
                </p>
            );

            console.log(message, error);
        } finally {
            setIsLoading(false);
        }
    };

    if (isLoading) return <LoadingComponent />;

    if (loadingError)
        return (
            <ErrorModal
                errorMessage={loadingError}
                onRetryClick={fetchData}
                onOkClick={() => setLoadingError(undefined)}
            />
        );

    return (
        <div>
            <h1>Cupones</h1>
            <SearchEntry onChange={(e) => setSearchText(e)} />

            <div id="toolbar" className="toolbar">
                <Button
                    id="addCategory"
                    variant="success"
                    onClick={() => navigate("../coupons-add")}
                >
                    <FontAwesomeIcon icon={faPlus} />
                </Button>
            </div>
            <Tabs
                tabs={["Activos", "Aplicados", "Cancelados", "Vencidos"]}
                selectedTabIndex={selectedStatus}
                onSelectedTabChanged={async (e) => {
                    setSelectedStatus(e);
                    fetchData(e);
                }}
            />

            <div id="couponsList">
                {getCoupons().map((x, i) => (
                    <div
                        className="panel"
                        key={i}
                        onClick={async () => {
                            if (navigator.clipboard === undefined) {
                                const input =
                                    document.createElement("textarea");
                                input.value = x.code;
                                document.body.appendChild(input);
                                input.select();
                                document.execCommand("copy");
                                document.body.removeChild(input);
                            } else {
                                await navigator.clipboard.writeText(x.code);
                            }
                        }}
                    >
                        <div className="d-flex align-items-center justify-content-between mb-2">
                            <span className="fw-bold fs-5">{x.code}</span>
                            {getStatusPill(x.status)}
                        </div>
                        <div className="d-flex align-items-center justify-content-between mb-2">
                            {x.description}
                            {x.status === eCouponStatus.Active && (
                                <Button
                                    variant="outline-danger"
                                    size="sm"
                                    onClick={async () => await cancelCoupon(x)}
                                >
                                    Cancelar
                                </Button>
                            )}
                        </div>
                        <div className="d-flex align-items-center justify-content-between mt-2">
                            <div className="lead fw-semibold">
                                {x.isPercentaje ? "" : "$"}
                                {x.amount}
                                {x.isPercentaje ? "%" : ""}
                            </div>
                            <div className="fw-bold">
                                {FormatDate(x.dueDate)}
                            </div>
                        </div>
                        {x.status === eCouponStatus.Applied && (
                            <div className="d-flex align-items-center justify-content-between mt-2">
                                <div className="lead">
                                    <div className="fw-semibold">
                                        {x.order?.customer?.name}
                                    </div>
                                    <span>Pedido:</span>
                                    <span className="fw-semibold">
                                        {x.orderIdApplied}
                                    </span>
                                </div>
                                <div className="fw-bold">
                                    {FormatDate(x.dateApplied)}
                                </div>
                            </div>
                        )}
                    </div>
                ))}
            </div>
        </div>
    );
};

export default CouponsListPage;
