import { Button, Modal } from "react-bootstrap";
import { useState } from "react";
import NonWorkingDaysPage from "./NonWorkingDays/NonWorkingDaysPage";
import { useNavigate } from "react-router-dom";

interface Props {
  workingDays: number[];
  onChange: (workingDays: number[]) => void;
}
const StoreSettingsWorkingDays = ({ workingDays, onChange }: Props) => {
  const buttonStyle = (dayOfWeekIndex: number) => {
    if (!workingDays) return "light";
    if (workingDays[dayOfWeekIndex] === 1) return "success";
    else return "light";
  };

  const [showNonWorkingDays, setShowNonWorkingDays] = useState<boolean>();

  const toggleDOW = (dayOfWeekIndex: number) => {
    workingDays[dayOfWeekIndex] = workingDays[dayOfWeekIndex] ? 0 : 1;
    onChange(workingDays);
  };

  const navigate = useNavigate();

  return (
    <div className="panel">
      <h6>Días hábiles </h6>
      <div className="d-flex gap-2 flex-wrap justify-content-between">
        <Button
          variant={`${buttonStyle(0)} flex-grow-1`}
          onClick={() => toggleDOW(0)}
        >
          Domingo
        </Button>
        <Button
          variant={`${buttonStyle(1)} flex-grow-1`}
          onClick={() => toggleDOW(1)}
        >
          Lunes
        </Button>
        <Button
          variant={`${buttonStyle(2)} flex-grow-1`}
          onClick={() => toggleDOW(2)}
        >
          Martes
        </Button>
        <Button
          variant={`${buttonStyle(3)} flex-grow-1`}
          onClick={() => toggleDOW(3)}
        >
          Miércoles
        </Button>
        <Button
          variant={`${buttonStyle(4)} flex-grow-1`}
          onClick={() => toggleDOW(4)}
        >
          Jueves
        </Button>
        <Button
          variant={`${buttonStyle(5)} flex-grow-1`}
          onClick={() => toggleDOW(5)}
        >
          Viernes
        </Button>
        <Button
          variant={`${buttonStyle(6)} flex-grow-1`}
          onClick={() => toggleDOW(6)}
        >
          Sábado
        </Button>
      </div>
      <Button className="mt-2" onClick={() => navigate("../nonworkingdays")}>
        Días inhábiles
      </Button>
    </div>
  );
};

export default StoreSettingsWorkingDays;
