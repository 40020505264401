import { Link } from "react-router-dom";

const HomePage = () => {
  return (
    <div className="pt-4">
      {/* <h1>¿No sabes ni por dónde empezar?</h1>
        <img
        style={{ maxWidth: "95%" }}
        src="https://www.eldiariodechihuahua.mx/chih/media/uploads/galeria/2022/06/30/20220630082221359-0-1946203.jpg"
      /> */}
      <h2>Guía rápida para lanzar tu tienda:</h2>
      <ol>
        <li>
          <Link to="store/settings">Registra los datos de tu tienda</Link>
        </li>
        <li>
          <Link to="products/categories">
            Crea las categorías de tus productos
          </Link>
        </li>
        <li>
          <Link to="products/">Da de alta tus productos</Link>
        </li>
      </ol>

      <h2>Guía de operación</h2>
      <ol>
        <li>
          <Link to="orders">Ve los pedidos que han levantado tus clientes</Link>
        </li>

        <li>
          <Link to="fulfill-orders">Surte los pedidos</Link>
        </li>
      </ol>
    </div>
  );
};

export default HomePage;
