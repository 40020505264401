import { AxiosResponse } from "axios";
import { ProductCategory } from "../models/productCategoryModel";
import { BuildUrl, CreateAxiosInstance } from "../helpers/apiHelpers";
import { convertImageUrlToBase64 } from "../helpers/imagesHelpers";

const axiosInstance = CreateAxiosInstance();
let _categories: ProductCategory[] = [];

interface GetProductCategoriesResponse {
  success: boolean;
  message?: string;
  categories: ProductCategory[];
}

// Returns a list of all product categories
export const GetProductCategories = async (): Promise<ProductCategory[]> => {
  // Return cached data if available
  if (_categories.length > 0) return _categories;

  // Otherwise, get data from API
  try {
    const response: AxiosResponse<GetProductCategoriesResponse> =
      await axiosInstance.get(BuildUrl("product-categories"));
    if (!response.data.success)
      throw new Error(
        response.data.message || "Error al obtener las categorías de productos"
      );

    _categories = response.data.categories;

    return _categories;
  } catch (error) {
    const message = "Error al obtener las categorías de productos " + error;
    console.log(message, error);
    throw new Error(message);
  }
};

interface GetProductCategoryResponse {
  success: boolean;
  message?: string;
  productCategory: ProductCategory;
}

// Returns a single product category by id
export const GetProductCategory = async (
  categoryId: number
): Promise<ProductCategory | undefined> => {
  try {
    const response: AxiosResponse<GetProductCategoryResponse> =
      await axiosInstance.get(
        BuildUrl(`product-categories/${categoryId}?includeSubCategories=true`)
      );

    if (!response.data.success)
      throw new Error(
        response.data.message || "Error getting product category"
      );

    return response.data.productCategory;
  } catch (error) {
    const message = "Error getting product category" + error;
    console.log(message, error);
    throw new Error(message);
  }
};

export type BottomLevelCategory = {
  categoryId: number;
  name: string;
};

// Returns a list of all bottom level categories
export const GetBottomLevelCategories = (): BottomLevelCategory[] => {
  let result: BottomLevelCategory[] = [];

  GetProductCategories().then((categories) => {
    categories.forEach((c) => {
      if (!c.subCategories || c.subCategories?.length === 0) {
        result.push({ categoryId: c.id, name: c.name });
      } else {
        c.subCategories.forEach((s) =>
          result.push({ categoryId: s.id, name: `${c.name} / ${s.name}` })
        );
      }
    });
  });

  return result.sort((a, b) =>
    a.name < b.name ? -1 : a.name > b.name ? 1 : 0
  );
};

interface CreateProductCategoryResponse {
  success: boolean;
  message?: string;
  productCategory: ProductCategory;
}

// Creates a new product category
export const CreateProductCategory = async (category: ProductCategory) => {
  try {
    if (category.imageUrl)
      category.imageBase64 = await convertImageUrlToBase64(category.imageUrl);

    const response: AxiosResponse<CreateProductCategoryResponse> =
      await axiosInstance.post(BuildUrl("product-categories"), category);
    console.log(response.data);
    if (!response.data.success)
      throw new Error(
        response.data.message || "Error al crear la categoría de producto"
      );

    // Reset cache to force reload
    _categories = [];

    // Returns the new category
    return response.data.productCategory;
  } catch (error) {
    let message = error;
    if (error instanceof Error) message = error.message;
    console.log(message, error);
    throw new Error(message as string);
  }
};

interface UpdateProductCategoryResponse {
  success: boolean;
  message?: string;
}

// Updates an existing product category
export const UpdateProductCategory = async (category: ProductCategory) => {
  try {
    if (category.imageUrl)
      category.imageBase64 = await convertImageUrlToBase64(category.imageUrl);

    const response: AxiosResponse<UpdateProductCategoryResponse> =
      await axiosInstance.put(BuildUrl(`product-categories`), category);
    if (!response.data.success)
      throw new Error(
        response.data.message || "Error al actualizar la categoría"
      );

    // Reset cache to force reload
    _categories = [];
  } catch (error) {
    const message = "Error al actualizar la categoría:" + error;
    console.log(message, error);
    throw new Error(message);
  }
};

interface DeleteProductCategoryResponse {
  success: boolean;
  message?: string;
}

// Deletes an existing product category
export const DeleteProductCategory = async (category: ProductCategory) => {
  try {
    const response: AxiosResponse<DeleteProductCategoryResponse> =
      await axiosInstance.delete(BuildUrl(`product-categories/${category.id}`));
    if (!response.data.success)
      throw new Error(
        response.data.message || "Error deleting product category"
      );
  } catch (error) {
    const message = error as string;
    console.log(message, error);
    throw new Error(message);
  }

  // Reset cache to force reload
  _categories = [];
};
