import { useEffect, useState } from "react";
import { User, UserDoorAssignation } from "../../api/models/userModel";
import { eActionType } from "../../api/models/common";
import { useNavigate, useParams } from "react-router-dom";
import TitleBar from "../../components/TitleBar";
import { Alert, Button, Form } from "react-bootstrap";
import { IError } from "../../api/models/errorModel";
import LoadingComponent from "../../components/LoadingComponent";
import {
    CreateUser,
    GetUser,
    GetUserDoorAssignation,
    UpdateUser,
} from "../../api/services/UsersServices";
import Tabs from "../../components/Tab";

const UpdateUserPage = () => {
    const [user, setUser] = useState<User>({
        name: "",
        eMail: "",
        password: "",
        doors: [],
    });
    const [confirmPassword, setConfirmPassword] = useState<string>("");

    const [doors, setDoors] = useState<UserDoorAssignation[]>([]);

    const [UserId, setUserId] = useState<string>("");
    const [changePassword, setChangePassword] = useState<boolean>(false);

    const [actionType, setActionType] = useState<eActionType>();
    const [selectedTab, setSelectedTab] = useState<number>(0);

    const [error, setError] = useState<IError>();

    const [loadingError, setLoadingError] = useState<React.ReactNode>();
    const [isLoading, setIsLoading] = useState(false);
    const [loadingText, setLoadingText] = useState<string | undefined>();

    const params = useParams();
    const navigate = useNavigate();

    useEffect(() => {
        if (params.userId) {
            getUser(params.userId);
        } else {
            setActionType(eActionType.Create);
            getDoors("{00000000-0000-0000-0000-000000000000}");
        }
    }, []);

    const getUser = async (userId: string) => {
        try {
            if (!userId) return;

            setIsLoading(true);
            setLoadingText("Cargando ...");
            setLoadingError(undefined);
            setError(undefined);

            const u = await GetUser(userId);
            setUser(u);
            setUserId(userId);

            await getDoors(userId);

            setActionType(eActionType.Update);
        } catch (error) {
            let message = error;
            if (error instanceof Error) message = error.message;
            setLoadingError(
                <p>
                    Ha ocurrido un error al cargar el usuario. <br /> Reintente
                    más tarde.
                </p>
            );
            console.log(message, error);
        } finally {
            setIsLoading(false);
        }
    };

    const getDoors = async (userId: string) => {
        try {
            const d = await GetUserDoorAssignation(userId);
            d.sort((a, b) => (a.doorName > b.doorName ? 1 : -1));
            setDoors(d);
        } catch (error) {
            let message = error;
            if (error instanceof Error) message = error.message;
            setLoadingError(
                <p>
                    Ha ocurrido un error al cargar los permisos del usuario.{" "}
                    <br /> Reintente más tarde.
                </p>
            );
            console.log(message, error);
        } finally {
            setIsLoading(false);
        }
    };

    const save = async () => {
        if (!validate()) return;

        try {
            setIsLoading(true);
            setLoadingText("Guardando ...");
            setError(undefined);

            if (actionType === eActionType.Create)
                await CreateUser(user, doors);
            if (actionType === eActionType.Update)
                await UpdateUser(user, UserId, doors);

            navigate(-1);
        } catch (error) {
            let message = error;
            if (error instanceof Error) message = error.message;
            setError({ message: message as string });
        } finally {
            setIsLoading(false);
            setLoadingText(undefined);
        }
    };

    const validate = () => {
        if (!user.name) {
            setError({
                message: "Escriba el nombre del usuario",
                property: "name",
            });
            return false;
        }

        if (!user.eMail) {
            setError({
                message: "Escriba el correo del usuario",
                property: "eMail",
            });
            return false;
        }
        if (actionType === eActionType.Create || changePassword) {
            if (!user.password) {
                setError({
                    message: "Escriba la contraseña del usuario",
                    property: "password",
                });
                return false;
            }

            if (user.password.length < 6) {
                setError({
                    message: "La contraseña debe tener al menos 6 caracteres",
                    property: "password",
                });
                return false;
            }

            if (!confirmPassword) {
                setError({
                    message: "Confirme la contraseña",
                    property: "confirmPassword",
                });
                return false;
            }

            if (user.password !== confirmPassword) {
                setError({
                    message: "Las contraseñas no coinciden",
                    property: "confirmPassword",
                });
                return false;
            }
        }
        setError(undefined);
        return true;
    };

    return (
        <div id="updateUser">
            <TitleBar
                title={
                    actionType === eActionType.Create
                        ? "Crear usuario"
                        : "Editar usuario"
                }
                backUrl="-1"
            />

            <Tabs
                tabs={["Generales", "Permisos"]}
                selectedTabIndex={selectedTab}
                onSelectedTabChanged={(s) => setSelectedTab(s)}
            />
            <Form className="panel">
                {selectedTab === 0 && (
                    <div id="generales">
                        <Form.Group id="name" className="formGroup">
                            <Form.Label className="required">Nombre</Form.Label>
                            <Form.Control
                                type="text"
                                placeholder="Nombre de la persona"
                                value={user.name}
                                isInvalid={error?.property === "name"}
                                onChange={(e) =>
                                    setUser({
                                        ...user,
                                        name: e.currentTarget.value,
                                    })
                                }
                            />
                        </Form.Group>

                        <Form.Group id="eMail" className="formGroup">
                            <Form.Label className="required">eMail</Form.Label>
                            <Form.Control
                                type="text"
                                placeholder="Correo del usuario"
                                value={user.eMail}
                                isInvalid={error?.property === "eMail"}
                                onChange={(e) =>
                                    setUser({
                                        ...user,
                                        eMail: e.currentTarget.value,
                                    })
                                }
                            />
                        </Form.Group>

                        {actionType === eActionType.Update &&
                            !changePassword && (
                                <Button
                                    className="mt-2"
                                    variant="outline-primary"
                                    onClick={() => setChangePassword(true)}
                                >
                                    Cambiar contraseña
                                </Button>
                            )}
                        {(actionType === eActionType.Create ||
                            changePassword) && (
                            <div>
                                <Form.Group id="Password" className="formGroup">
                                    <Form.Label className="required">
                                        Contraseña
                                    </Form.Label>
                                    <Form.Control
                                        type="password"
                                        placeholder="Contraseña"
                                        value={user.password}
                                        isInvalid={
                                            error?.property === "password"
                                        }
                                        onChange={(e) =>
                                            setUser({
                                                ...user,
                                                password: e.currentTarget.value,
                                            })
                                        }
                                    />
                                </Form.Group>

                                <Form.Group
                                    id="ConfirmPassword"
                                    className="formGroup"
                                >
                                    <Form.Label className="required">
                                        Confirmar contraseña
                                    </Form.Label>
                                    <Form.Control
                                        type="password"
                                        placeholder="Confirmar la contraseña"
                                        value={confirmPassword}
                                        isInvalid={
                                            error?.property ===
                                            "confirmPassword"
                                        }
                                        onChange={(e) =>
                                            setConfirmPassword(
                                                e.currentTarget.value
                                            )
                                        }
                                    />
                                </Form.Group>
                            </div>
                        )}
                    </div>
                )}

                {selectedTab === 1 && (
                    <div id="permisos">
                        <div>
                            {doors.map((door, index) => (
                                <Form.Check
                                    key={index}
                                    type="checkbox"
                                    label={door.doorName}
                                    checked={door.doorAssigned}
                                    onChange={(e) => {
                                        door.doorAssigned = e.currentTarget
                                            .checked
                                            ? true
                                            : false;
                                        setDoors([...doors]);
                                    }}
                                />
                            ))}
                        </div>
                    </div>
                )}
            </Form>

            {error && (
                <Alert className="mt-4 mb-2" variant="danger">
                    {error.message}
                </Alert>
            )}

            <div className="bottom-toolbar">
                <Button size="lg" variant="success" onClick={save}>
                    Guardar
                </Button>
            </div>

            {isLoading && <LoadingComponent loadingText={loadingText} />}
        </div>
    );
};

export default UpdateUserPage;
