import { Button, FormCheck, FormGroup, FormLabel } from "react-bootstrap";
import NumericInput from "../../components/NumericInput";
import { useState } from "react";
import LocalDeliveryZones from "./LocalDelivery/LocalDeliveryZones";

interface Props {
  pickupEnabled: boolean;
  pickupMinimumAmount: number;
  pickupDeliveryTime: number;
  pickupDeliveryFee: number;
  deliveryEnabled: boolean;
  deliveryMinimumAmount: number;
  deliveryFreeShippingMinimumAmount: number;
  localDeliveryTime: number;
  localDeliveryFee: number;
  deliveryTime: number;
  deliveryFee: number;
  onPropertyChanged: (propertyName: string, value: any) => void;
}
const StoreSettingsDeliveryMethods = ({
  pickupEnabled,
  pickupMinimumAmount,
  pickupDeliveryTime,
  pickupDeliveryFee,
  deliveryEnabled,
  deliveryMinimumAmount,
  deliveryFreeShippingMinimumAmount,
  localDeliveryFee,
  localDeliveryTime,
  deliveryTime,
  deliveryFee,
  onPropertyChanged,
}: Props) => {
  const [localDeliveryZonesVisible, setLocalDeliveryZonesVisible] =
    useState<boolean>(false);
  return (
    <div>
      <div id="pickup" className="panel">
        <FormLabel>Recoger en tienda</FormLabel>
        <FormCheck
          label="Puede recoger en tienda"
          checked={pickupEnabled}
          onChange={() => onPropertyChanged("pickupEnabled", !pickupEnabled)}
        />
        <FormGroup className="formGroup">
          <FormLabel>Monto mínimo</FormLabel>
          <NumericInput
            disabled={!pickupEnabled}
            value={pickupMinimumAmount}
            onValueChanged={(e) => onPropertyChanged("pickupMinimumAmount", e)}
            placeholder="Monto mínimo para recoger en tienda"
          />
        </FormGroup>
        <FormGroup className="formGroup">
          <FormLabel>Tiempo de entrega</FormLabel>
          <NumericInput
            disabled={!pickupEnabled}
            placeholder="Tiempo de entrega en tienda (en días)"
            value={pickupDeliveryTime}
            onValueChanged={(e) => onPropertyChanged("pickupDeliveryTime", e)}
          />
        </FormGroup>
        <FormGroup className="formGroup">
          <FormLabel>Gastos de entrega</FormLabel>
          <NumericInput
            disabled={!pickupEnabled}
            placeholder="Monto por entrega en tienda"
            value={pickupDeliveryFee}
            onValueChanged={(e) => onPropertyChanged("pickupDeliveryFee", e)}
          />
        </FormGroup>
      </div>

      <div id="delivery" className="panel">
        <FormLabel>Envío a domicilio</FormLabel>
        <FormCheck
          label="Puede enviar a domicilio"
          checked={deliveryEnabled}
          onChange={() =>
            onPropertyChanged("deliveryEnabled", !deliveryEnabled)
          }
        />
        <FormGroup className="formGroup">
          <FormLabel>Monto mínimo</FormLabel>
          <NumericInput
            disabled={!deliveryEnabled}
            value={deliveryMinimumAmount}
            onValueChanged={(e) =>
              onPropertyChanged("deliveryMinimumAmount", e)
            }
            placeholder="Monto mínimo para envío a domicilio"
          />
        </FormGroup>

        <FormGroup className="formGroup">
          <FormLabel>Envío gratis</FormLabel>
          <NumericInput
            disabled={!deliveryEnabled}
            value={deliveryFreeShippingMinimumAmount}
            onValueChanged={(e) =>
              onPropertyChanged("deliveryFreeShippingMinimumAmount", e)
            }
            placeholder="Monto mínimo para envío gratis a domicilio"
          />
        </FormGroup>

        <FormGroup className="formGroup">
          <FormLabel>Tiempo de entrega (local)</FormLabel>
          <NumericInput
            disabled={!deliveryEnabled}
            placeholder="Tiempo de entrega local (en días)"
            value={localDeliveryTime}
            onValueChanged={(e) => onPropertyChanged("localDeliveryTime", e)}
          />
        </FormGroup>
        <FormGroup className="formGroup">
          <FormLabel>Gastos de envío (local)</FormLabel>
          <NumericInput
            disabled={!deliveryEnabled}
            placeholder="Gastos de envío local"
            value={localDeliveryFee}
            onValueChanged={(e) => onPropertyChanged("localDeliveryFee", e)}
          />
        </FormGroup>

        <FormGroup className="formGroup">
          <FormLabel>Tiempo de entrega (foráneo)</FormLabel>
          <NumericInput
            disabled={!deliveryEnabled}
            placeholder="Tiempo de entrega foráneo (en días)"
            value={deliveryTime}
            onValueChanged={(e) => onPropertyChanged("deliveryTime", e)}
          />
        </FormGroup>
        <FormGroup className="formGroup">
          <FormLabel>Gastos de envío (foráneo)</FormLabel>
          <NumericInput
            disabled={!deliveryEnabled}
            placeholder="Gastos de envío foráneo"
            value={deliveryFee}
            onValueChanged={(e) => onPropertyChanged("deliveryFee", e)}
          />
        </FormGroup>

        <Button
          className="mt-4"
          onClick={() => setLocalDeliveryZonesVisible(true)}
        >
          Áreas de entrega local
        </Button>

        <LocalDeliveryZones
          show={localDeliveryZonesVisible}
          onClose={() => setLocalDeliveryZonesVisible(false)}
        />
      </div>
    </div>
  );
};

export default StoreSettingsDeliveryMethods;
