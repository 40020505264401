import { Alert, Button, Modal } from "react-bootstrap";
import { OrderPayment } from "../../../api/models/orderModel";
import { useEffect, useState } from "react";
import OrderPayments from "../OrdersReceivable/OrderPayments";
import { FormatDecimal } from "../../../api/helpers/formatters";
import {
    GetOrdersToDeliverViewModel,
    PrimeOrderPayments,
} from "../../../api/services/ordersServices";

interface Props {
    order: GetOrdersToDeliverViewModel;
    onClose: () => void;
    onDelivered: (orderId: number, payments: Array<OrderPayment>) => void;
}

const DeliverOrder = ({ order, onClose, onDelivered }: Props) => {
    const [showPayments, setShowPayments] = useState<boolean>(false);
    const [payments, setPayments] = useState<Array<OrderPayment>>([]);

    const [errorText, setErrorText] = useState("");

    useEffect(() => {
        inicializarPagos();
    }, []);

    const inicializarPagos = async () => {
        const saldoInicial = order.totalFullfilled;
        setShowPayments(saldoInicial > 0);

        if (saldoInicial === 0) return;

        try {
            const primed = await PrimeOrderPayments();
            setPayments(primed);
        } catch (error) {
            setErrorText(error as string);
        }
    };

    const handlePaymentChanged = (orderPayments: Array<OrderPayment>) => {
        // Valida que los pagos no excedan el total del pedido
        const saldo = calcularSaldo(orderPayments);
        if (saldo < 0) {
            setErrorText(
                "El total de los pagos no puede ser mayor al total surtido del pedido."
            );
            return;
        }

        setErrorText("");
        setPayments(orderPayments);
    };

    const calcularSaldo = (orderPayments: Array<OrderPayment>) => {
        const sumPayments = orderPayments.reduce(
            (accumulator, currentObject) => {
                return accumulator + currentObject.amount;
            },
            0
        );
        return order.totalFullfilled - sumPayments;
    };

    const handleEntregar = () => {
        setErrorText("");

        if (!validarReferenciasFormaPago()) return;

        // Si queda saldo pendiente, pregunta si se desea entregar y dejar el saldo pendiente
        const saldo = calcularSaldo(payments);
        if (saldo > 0) {
            if (
                !window.confirm(
                    `Queda un saldo pendiente de ${FormatDecimal(
                        saldo,
                        true
                    )}. ¿Desea entregar y dejar el saldo pendiente?`
                )
            )
                return;
        }

        onDelivered(order.id, payments);
    };

    const validarReferenciasFormaPago = () => {
        console.log(payments);
        const pagosSinReferencia = payments.filter(
            (p) =>
                !p.paymentReference &&
                p.paymentMethod.referenceRequired &&
                p.amount > 0
        );
        if (pagosSinReferencia.length === 0) return true;

        setErrorText(
            `La forma de pago ${pagosSinReferencia[0].paymentMethod.name} requiere referencia.`
        );

        return false;
    };

    return (
        <Modal show={true} onHide={onClose}>
            <Modal.Header closeButton>
                <Modal.Title>Entregar pedido {order.id}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div>
                    <strong>{order.customerName}</strong>
                </div>
                <div>Total original: {FormatDecimal(order.total, true)}</div>
                <div>
                    Total surtido:{" "}
                    <strong>
                        {FormatDecimal(order.totalFullfilled, true)}
                    </strong>
                </div>

                {showPayments && (
                    <div className="mt-2">
                        <OrderPayments
                            orderTotal={order.totalFullfilled}
                            orderPayments={payments}
                            onChange={handlePaymentChanged}
                        />
                    </div>
                )}
                {!showPayments && (
                    <div className="mt-3">
                        <p>¿ Marcar el pedido como entregado ?</p>
                    </div>
                )}
            </Modal.Body>
            <Modal.Footer>
                {errorText && (
                    <Alert className="mt-4 mb-2 w-100" variant="danger">
                        {errorText}
                    </Alert>
                )}
                <Button
                    disabled={errorText !== ""}
                    variant="success"
                    onClick={handleEntregar}
                >
                    Entregar
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default DeliverOrder;
