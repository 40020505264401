import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
    FechaEntregaDefault,
    GetFechaDesde,
    GetFechaHasta,
    GetISODate,
    RangoFechas,
} from "../../../api/helpers/datesHelpers";
import { FormatDecimal } from "../../../api/helpers/formatters";
import { ProductOrdered } from "../../../api/models/orderModel";
import { GetProductsOrdered } from "../../../api/services/ordersServices";
import DateRangeLink from "../../../components/DateRangeLink";
import LabelGroup from "../../../components/LabelGroup";
import SearchEntry from "../../../components/SearchEntry";
import Tabs from "../../../components/Tab";
import TitleBar from "../../../components/TitleBar";
import FilterPanel from "../../../components/FilterPanel";
import ProductsOrderedFilterPanel, {
    ProductsOrderedFilterProps,
} from "./ProductsOrderedFilterPanel";
import LoadingComponent from "../../../components/LoadingComponent";
import ErrorModal from "../../../components/ErrorModal";
import { GetErrorMessage } from "../../../api/helpers/apiHelpers";
import { useSession } from "../../../api/context/SessionContext";

const ProductsOrderedListPage = () => {
    const navigate = useNavigate();
    const session = useSession();

    const [searchText, setSearchText] = useState<string | undefined>();
    const [rangoFechas, setRangoFechas] = useState<RangoFechas>({
        Desde: GetFechaDesde(new Date()),
        Hasta: GetFechaHasta(FechaEntregaDefault()),
    });

    const [filterExpanded, setFilterExpanded] = useState<boolean>(false);
    const [filterBySupplier, setFilterBySupplier] = useState<boolean>(false);
    const [filteredSupplier, setFilteredSupplier] = useState<string>();

    const [selectedStatus, setSelectedStatus] = useState<number>(0);

    const [isLoading, setIsLoading] = useState(true);
    const [errorMessage, setErrorMessage] = useState<React.ReactNode>();

    const [ordersProducts, setOrdersProducts] = useState<ProductOrdered[]>([]);

    useEffect(() => {
        // Valida que tenga acceso a esta opción
        if (
            !session.isLoggedIn() ||
            !session.hasDoor("eb2fa22f-662b-4bec-a239-c250687bdafe")
        ) {
            alert("No tiene permiso para ingresar a esta sección");
            navigate("../");
            return;
        }

        fetchData();
    }, [rangoFechas, selectedStatus]);

    const fetchData = async () => {
        try {
            setIsLoading(true);
            setErrorMessage(undefined);

            const response = await GetProductsOrdered(
                selectedStatus,
                rangoFechas
            );
            if (!response.success) {
                setErrorMessage(response.message);
                return;
            }
            setOrdersProducts(response.productsOrdered);
        } catch (error) {
            setErrorMessage(GetErrorMessage(error));
        } finally {
            setIsLoading(false);
        }
    };

    const filterProducts = () => {
        let ds = ordersProducts;

        if (filterBySupplier && filteredSupplier) {
            var filter = filteredSupplier?.toLowerCase();
            ds = ds.filter((x) =>
                x.product.supplierName?.toLowerCase().includes(filter)
            );
        }

        if (searchText) {
            let searchTextLower = searchText.toLocaleLowerCase();
            ds = ds.filter((x) =>
                x.product.name.toLocaleLowerCase().includes(searchTextLower)
            );
        }
        return ds;
    };

    const showProduct = (x: ProductOrdered) => {
        const params = new URLSearchParams();
        params.append("status", selectedStatus.toString());
        params.append("desde", GetISODate(rangoFechas.Desde));
        params.append("hasta", GetISODate(rangoFechas.Hasta));

        navigate(`../products-ordered/${x.product.id}?${params.toString()}`);
    };

    const handleApplyFilters = (e: ProductsOrderedFilterProps) => {
        setFilterBySupplier(e.filterBySupplier);
        if (e.filterBySupplier) setFilteredSupplier(e.filteredSupplier);

        setFilterExpanded(false);
    };

    if (isLoading) return <LoadingComponent />;

    if (errorMessage)
        return (
            <ErrorModal
                errorMessage={errorMessage}
                onRetryClick={fetchData}
                onOkClick={() => setErrorMessage(undefined)}
            />
        );

    return (
        <div>
            <TitleBar title="Productos Pedidos">
                <div className="d-flex">
                    <LabelGroup caption="Fecha de entrega:">
                        <DateRangeLink
                            rangoFechas={rangoFechas}
                            onChange={function (nuevoRango: RangoFechas): void {
                                setRangoFechas(nuevoRango);
                            }}
                        />
                    </LabelGroup>
                </div>
            </TitleBar>

            <div className="d-flex">
                <SearchEntry
                    className="flex-grow-1"
                    onChange={(e) => setSearchText(e)}
                />
                <FilterPanel
                    className="ms-2"
                    expanded={filterExpanded}
                    onExpandedChanged={(e) => setFilterExpanded(e)}
                >
                    <ProductsOrderedFilterPanel
                        filterBySupplier={filterBySupplier}
                        filteredSupplier={filteredSupplier}
                        onApplyFilters={(e) => handleApplyFilters(e)}
                    />
                </FilterPanel>
            </div>

            <Tabs
                tabs={[
                    "por Surtir",
                    "Surtiendo",
                    "Empacados",
                    "Enviados",
                    "Entregados",
                    "Cancelados",
                ]}
                selectedTabIndex={selectedStatus}
                onSelectedTabChanged={(e) => setSelectedStatus(e)}
            />

            <div id="list">
                {filterProducts().map((x, i) => (
                    <div
                        key={i}
                        className="panel"
                        onClick={() => showProduct(x)}
                    >
                        <div className="d-flex">
                            <div>
                                <img
                                    style={{
                                        maxWidth: "64px",
                                        maxHeight: "64px",
                                    }}
                                    alt={x.product.name}
                                    src={x.product.thumbUrl}
                                />
                            </div>
                            <div className="flex-grow-1 ps-2 ">
                                <div>
                                    <strong>{x.product.name}</strong>
                                </div>
                                <div>
                                    <strong>{x.quantity}</strong>{" "}
                                    {x.product.unitOfMeasure}
                                </div>
                                <div>
                                    <small>{x.product.supplierName}</small>
                                </div>
                            </div>
                        </div>
                    </div>
                ))}
            </div>

            <div
                id="totals"
                className="d-flex justify-content-between mt-4 mb-4"
            >
                <LabelGroup caption="No. Productos">
                    {filterProducts().length}
                </LabelGroup>
                <LabelGroup caption="Cantidad total">
                    {FormatDecimal(
                        filterProducts().reduce(
                            (sum, item) => sum + item.quantity,
                            0
                        ),
                        false
                    )}
                </LabelGroup>
            </div>
        </div>
    );
};

export default ProductsOrderedListPage;
