import { Modal } from "react-bootstrap";
import { GetProducts } from "../../api/services/productServices";
import { Product } from "../../api/models/productModel";
import { useEffect, useState } from "react";
import SearchEntry from "../../components/SearchEntry";
import LoadingComponent from "../../components/LoadingComponent";
import ErrorModal from "../../components/ErrorModal";

interface Props {
  onClose: () => void;
  onSelected: (product: Product) => void;
}

const LookUpProduct = (props: Props) => {
  const [products, setProducts] = useState<Product[]>([]);
  const [searchText, setSearchText] = useState<string | undefined>();

  const [isLoading, setIsLoading] = useState(true);
  const [loadingError, setLoadingError] = useState<React.ReactNode>();

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      setIsLoading(true);
      setLoadingError(undefined);

      const result = await GetProducts();
      setProducts(result);
    } catch (error) {
      let message = error;
      if (error instanceof Error) message = error.message;
      setLoadingError(
        <p>
          Ha ocurrido un error al cargar los datos. <br /> Reintente más tarde.
        </p>
      );

      console.log(message, error);
    } finally {
      setIsLoading(false);
    }
  };

  const getProducts = () => {
    let ds = [...products];

    if (searchText) {
      let searchTextLower = searchText.toLocaleLowerCase();
      ds = ds.filter((x) =>
        x.name.toLocaleLowerCase().includes(searchTextLower)
      );
    }

    return ds.sort((a, b) =>
      a.order < b.order ? -1 : a.order > b.order ? 1 : 0
    );
  };

  if (isLoading) return <LoadingComponent />;

  if (loadingError)
    return <ErrorModal errorMessage={loadingError} onRetryClick={fetchData} />;

  return (
    <Modal show={true} onHide={props.onClose}>
      <Modal.Header closeButton>
        <Modal.Title>Seleccionar producto</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <SearchEntry onChange={(e) => setSearchText(e)} />

        <div>
          {getProducts().map((x, i) => (
            <div key={i} className="m-2 mb-3">
              <div className="row" onClick={() => props.onSelected(x)}>
                <div className="col-2">
                  <img
                    style={{ maxWidth: "48px", maxHeight: "48px" }}
                    src={x.thumbUrl}
                  />
                </div>
                <div className="col d-flex align-items-center border-bottom">
                  {x.name}
                </div>
              </div>
            </div>
          ))}
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default LookUpProduct;
