import {
    createContext,
    ReactNode,
    useContext,
    useEffect,
    useState,
} from "react";
import { tSession, tSessionContext } from "../models/sessionModel";
import React from "react";
import { jwtDecode, JwtPayload } from "jwt-decode";
import { tenantId } from "../services/tenantServices";

const SessionContext = createContext({} as tSessionContext);

type tSessionContextProviderProps = {
    children: ReactNode;
};

export function SessionContextProvider({
    children,
}: tSessionContextProviderProps) {
    const [session, setSession] = useState({} as tSession);

    // Al iniciar la aplicación, se valida si existe un token en el local storage e inicia sesión con el
    useEffect(() => {
        const token = localStorage.getItem("token");
        if (token) {
            logIn(token);
        }
    }, []);

    // Regresa true si existe un usuario loggeado
    function isLoggedIn() {
        if (!session) return false;
        if (!session.userId) return false;

        if (session.tenantCode !== tenantId) return false;

        //TODO: Validate session expiration
        return true;
    }

    // Inicia sesión con el token recibido
    function logIn(token: string) {
        // Obtiene la información del token
        try {
            const decodedToken: any = jwtDecode<JwtPayload>(token);

            // Asigna la información del token a la sesión
            let newSession = { ...session };
            newSession.userId = decodedToken.sub;
            newSession.userName = decodedToken.name;
            newSession.expires = new Date(decodedToken.exp * 1000);
            newSession.doors = decodedToken.doors;
            newSession.tenantCode = decodedToken.tenant_code;

            setSession(newSession);

            // Guarda el token en el local storage
            localStorage.setItem("token", token);

            return newSession;
        } catch (error) {
            console.log("Error processing token: ", error);
            console.log("Token: ", token);
            return null;
        }
    }

    // Regresa la sesion del usuario loggeado
    function getLoggedUser() {
        if (isLoggedIn()) return session;
        return null;
    }

    // Cierra la sesión del usuario
    function logOut() {
        setSession({} as tSession);
        localStorage.removeItem("token");
    }

    function hasDoor(doorId: string) {
        const user = session;
        if (!user) return false;
        if (!user.doors) return false;

        const door = user.doors.find((door) => door.doorId === doorId);

        if (door == null) console.log("No tiene permiso", doorId);
        return door != null;
    }

    return (
        <SessionContext.Provider
            value={{
                isLoggedIn,
                logIn,
                getLoggedUser,
                logOut: logOut,
                hasDoor,
            }}
        >
            {children}
        </SessionContext.Provider>
    );
}

export function useSession() {
    return useContext(SessionContext);
}
