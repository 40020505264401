import { FormControl, FormGroup, FormLabel } from "react-bootstrap";
import { IError } from "../../api/models/errorModel";
import SelectImage from "../../components/SelectImage";
import RemoveImageBackground from "../../components/RemoveImageBackground";

interface Props {
  storeName: string;
  logoUrl?: string;
  error?: IError;
  onPropertyChanged: (propertyName: string, value: any) => void;
}

const StoreSettingsNameLogo = ({
  storeName,
  logoUrl,
  error,
  onPropertyChanged,
}: Props) => {
  return (
    <div>
      <div id="name" className="panel">
        <FormGroup>
          <FormLabel>Nombre</FormLabel>
          <FormControl
            type="text"
            placeholder="Nombre de la tienda"
            value={storeName}
            isInvalid={error?.property === "storeName"}
            onChange={(e) =>
              onPropertyChanged("storeName", e.currentTarget.value)
            }
          />
        </FormGroup>

        <FormGroup className="mt-2">
          <FormLabel>Logotipo</FormLabel>
          <SelectImage
            imageUrl={logoUrl ? logoUrl : "/img/no-image.png"}
            imageWidth="50px"
            imageHeight="50px"
            imageAlt="El logotipo de la tienda"
            buttonVariant="primary"
            onImageChanged={(imagesUrl) =>
              onPropertyChanged("logoUrl", imagesUrl[0])
            }
          />
          <RemoveImageBackground
            sourceImageUrl={logoUrl ? logoUrl : "/img/no-image.png"}
            onBackgroundRemoved={(url) => onPropertyChanged("logoUrl", url)}
            className="w-100 my-2"
          />
        </FormGroup>
      </div>
    </div>
  );
};

export default StoreSettingsNameLogo;
