import { faStore, faTruck } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { eDeliveryType } from "../../../api/models/orderModel";

interface DeliveryTypeBadgeProps extends React.HTMLAttributes<HTMLDivElement> {
  deliveryType: eDeliveryType;
  className?: string;
}

const DeliveryTypeBadge = ({
  deliveryType,
  className,
}: DeliveryTypeBadgeProps) => {
  return (
    <div className={className}>
      {deliveryType === eDeliveryType.Delivery && (
        <span
          className="badge rounded-pill bg-primary"
          style={{ height: "1.4rem" }}
        >
          <FontAwesomeIcon className="me-1" icon={faTruck} /> Domicilio
        </span>
      )}
      {deliveryType === eDeliveryType.Pickup && (
        <span
          className="badge rounded-pill bg-warning"
          style={{ height: "1.4rem" }}
        >
          <FontAwesomeIcon className="me-1" icon={faStore} />
          Tienda
        </span>
      )}
    </div>
  );
};

export default DeliveryTypeBadge;
