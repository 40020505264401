import { useState } from "react";
import { Alert, Button, Form, Modal } from "react-bootstrap";
import { ProductSubCategory } from "../../../api/models/productCategoryModel";
import { IError } from "../../../api/models/errorModel";
import { eActionType } from "../../../api/models/common";

interface UpdateProductSubCategoryProps {
    actionType: eActionType;
    subCategory: ProductSubCategory;
    onHide: () => void;
    onSave: (subCategory: ProductSubCategory) => void;
    onDelete: (subCategory: ProductSubCategory) => void;
}

const UpdateProductSubCategory = (props: UpdateProductSubCategoryProps) => {
    const [subCategory, setSubCategory] = useState<ProductSubCategory>(
        props.subCategory
    );

    const [error, setError] = useState<IError>();

    function save() {
        if (!validate()) return;
        props.onSave(subCategory);
    }

    function validate() {
        if (!subCategory.name) {
            setError({
                message: "Escriba el nombre de la sub-categoría",
                property: "name",
            });
            return false;
        }

        setError(undefined);
        return true;
    }

    return (
        <Modal show={true} backdrop="static" onHide={props.onHide}>
            <Modal.Header closeButton>
                {props.actionType === eActionType.Create
                    ? "Agregar sub-categoría"
                    : "Editar sub-categoría"}
            </Modal.Header>
            <Modal.Body>
                <Form>
                    <Form.Group>
                        <Form.Label>Nombre:</Form.Label>
                        <Form.Control
                            type="text"
                            placeholder="Nombre de la sub-categoría"
                            value={subCategory.name}
                            isInvalid={error?.property === "name"}
                            onChange={(e) =>
                                setSubCategory({
                                    ...subCategory,
                                    name: e.currentTarget.value,
                                })
                            }
                        />
                    </Form.Group>
                    {props.actionType === eActionType.Update && (
                        <Form.Group className="formGroup">
                            <Form.Label>Orden</Form.Label>
                            <Form.Control
                                type="number"
                                min="0"
                                placeholder="Orden de despliegue"
                                value={
                                    subCategory.order !== 0
                                        ? subCategory.order
                                        : undefined
                                }
                                onChange={(e) =>
                                    setSubCategory({
                                        ...subCategory,
                                        order: e.currentTarget.value
                                            ? parseInt(e.currentTarget.value)
                                            : 0,
                                    })
                                }
                            />
                        </Form.Group>
                    )}
                </Form>
                {error && (
                    <Alert className="mt-4 mb-2" variant="danger">
                        {error.message}
                    </Alert>
                )}
            </Modal.Body>
            <Modal.Footer>
                <div className="bottom-toolbar">
                    {props.actionType === eActionType.Update && (
                        <div className="bottom-toolbar--left">
                            <Button
                                size="lg"
                                variant="outline-danger"
                                onClick={() => props.onDelete(subCategory)}
                            >
                                Eliminar
                            </Button>
                        </div>
                    )}
                    <div className="bottom-toolbar--right">
                        <Button size="lg" variant="success" onClick={save}>
                            Guardar
                        </Button>
                    </div>
                </div>
            </Modal.Footer>
        </Modal>
    );
};

export default UpdateProductSubCategory;
