import { useEffect, useState } from "react";
import { LocalDeliveryZone } from "../../../api/models/localDeliveryZone";
import {
    CreateLocalDeliveryZone,
    DeleteLocalDeliveryZone,
    GetLocalDeliveryZones,
    UpdateLocalDeliveryZone,
} from "../../../api/services/localDeliveryZoneServices";
import { Alert, Button, Form, Modal } from "react-bootstrap";
import SearchEntry from "../../../components/SearchEntry";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { eActionType } from "../../../api/models/common";
import { IError } from "../../../api/models/errorModel";
import LoadingComponent from "../../../components/LoadingComponent";
import ErrorModal from "../../../components/ErrorModal";

interface Props {
    show: boolean;
    onClose: () => void;
}

const LocalDeliveryZones = ({ show: visible, onClose }: Props) => {
    const [zones, setZones] = useState<LocalDeliveryZone[]>([]);
    const [searchText, setSearchText] = useState<string | undefined>();

    const [actionType, setActionType] = useState<eActionType>(eActionType.None);
    const [currentZone, setCurrentZone] = useState<LocalDeliveryZone>({
        id: 0,
        zipCode: "",
        name: "",
    });

    const [error, setError] = useState<IError>();
    const [loadingError, setLoadingError] = useState<React.ReactNode>();

    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        fetchData();
    }, []);

    const fetchData = async () => {
        try {
            setLoadingError(undefined);
            setIsLoading(true);
            let result = await GetLocalDeliveryZones();
            setZones(result);
        } catch (error) {
            let message = error;
            if (error instanceof Error) message = error.message;
            setLoadingError(
                <p>
                    Ha ocurrido un error al cargar las zonas de entrega. <br />{" "}
                    Reintente más tarde.
                </p>
            );
            console.log(message, error);
        } finally {
            setIsLoading(false);
        }
    };

    const handleSave = async () => {
        if (currentZone.zipCode === "") {
            setError({
                message: "Escriba el código postal",
                property: "zipCode",
            });
            return false;
        }

        try {
            setLoadingError(undefined);
            setError(undefined);

            switch (actionType) {
                case eActionType.Create: {
                    zones.push(await CreateLocalDeliveryZone(currentZone));
                    break;
                }
                case eActionType.Update: {
                    UpdateLocalDeliveryZone(currentZone);
                    var index = zones.findIndex((x) => x.id === currentZone.id);
                    zones[index] = currentZone;
                }
            }
            setActionType(eActionType.None);
        } catch (error) {
            let message = error;
            if (error instanceof Error) message = error.message;
            setError({ message: message as string });
        } finally {
            setIsLoading(false);
        }
    };

    const handleDelete = async () => {
        if (!window.confirm("¿ Desea eliminar el código postal?")) return;

        try {
            setLoadingError(undefined);
            setError(undefined);

            DeleteLocalDeliveryZone(currentZone);

            var index = zones.findIndex((x) => x.id === currentZone.id);
            zones.splice(index, 1);

            setActionType(eActionType.None);
        } catch (error) {
            let message = error;
            if (error instanceof Error) message = error.message;
            setError({ message: message as string });
            console.log(message, error);
        } finally {
            setIsLoading(false);
        }
    };

    if (isLoading) return <LoadingComponent />;

    if (loadingError)
        return (
            <ErrorModal
                errorMessage={loadingError}
                onRetryClick={fetchData}
                onOkClick={() => onClose()}
            />
        );

    if (actionType !== eActionType.None)
        return (
            <Modal show={true} onHide={() => setActionType(eActionType.None)}>
                <Modal.Header closeButton>Agregar</Modal.Header>
                <Modal.Body>
                    <Form.Group className="formGroup">
                        <Form.Label>Código postal</Form.Label>
                        <Form.Control
                            placeholder="Código postal"
                            value={currentZone?.zipCode}
                            isInvalid={error?.property === "zipCode"}
                            onChange={(e) =>
                                setCurrentZone({
                                    ...currentZone,
                                    zipCode: e.currentTarget.value,
                                })
                            }
                        />
                    </Form.Group>
                    <Form.Group className="formGroup">
                        <Form.Label>Nombre</Form.Label>
                        <Form.Control
                            placeholder="Nombre"
                            value={currentZone?.name}
                            onChange={(e) =>
                                setCurrentZone({
                                    ...currentZone,
                                    name: e.currentTarget.value,
                                })
                            }
                        />
                    </Form.Group>
                </Modal.Body>
                <Modal.Footer>
                    {error && (
                        <Alert className="mt-4 mb-2 w-100" variant="danger">
                            {error.message}
                        </Alert>
                    )}
                    {actionType === eActionType.Update && (
                        <Button
                            size="lg"
                            variant="outline-danger"
                            onClick={handleDelete}
                        >
                            Eliminar
                        </Button>
                    )}
                    <Button variant="success" onClick={handleSave}>
                        Guardar
                    </Button>
                </Modal.Footer>
            </Modal>
        );

    return (
        <Modal show={visible} onHide={onClose}>
            <Modal.Header closeButton>Áreas de entrega local</Modal.Header>
            <Modal.Body>
                <div id="toolbar" className="toolbar">
                    <SearchEntry onChange={(e) => setSearchText(e)} />
                    <Button
                        id="addCategory"
                        variant="success"
                        onClick={() => {
                            setCurrentZone({
                                id: 0,
                                zipCode: "",
                                name: "",
                            });
                            setActionType(eActionType.Create);
                        }}
                    >
                        <FontAwesomeIcon icon={faPlus} />
                    </Button>
                </div>

                <div className="list mt-2">
                    {zones.map((x, i) => (
                        <div
                            key={i}
                            className="list-item"
                            onClick={() => {
                                setCurrentZone(x);
                                setActionType(eActionType.Update);
                            }}
                        >
                            {x.zipCode} {x.name}
                        </div>
                    ))}
                </div>
            </Modal.Body>
        </Modal>
    );
};

export default LocalDeliveryZones;
