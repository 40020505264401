import {
  faChevronLeft,
  faChevronRight,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ChangeEvent, useRef, useState } from "react";
import { Button } from "react-bootstrap";
import SelectImage from "../../components/SelectImage";

interface Props {
  carouselUrls: string[];
  onPropertyChanged: (propertyName: string, value: any) => void;
}

const StoreSettingsCarousel = ({ carouselUrls, onPropertyChanged }: Props) => {
  const [carouselIndex, setCarouselIndex] = useState<number>(
    carouselUrls.length === 0 ? -1 : 0
  );

  function handleAddCarouselImages(imageUrls: string[]) {
    let urls = Array.from(carouselUrls);
    imageUrls.map((x) => urls.push(x));

    let index = urls.length - 1;

    onPropertyChanged("carouselUrls", urls);
    setCarouselIndex(index);
  }

  const changeCarouselImageFileInput = useRef<HTMLInputElement>(null);
  function handleChangeCarouselImage(event: ChangeEvent<HTMLInputElement>) {
    const files = event.target.files;
    if (!files) return;

    if (carouselIndex < 0) return;

    let urls = carouselUrls;
    urls[carouselIndex] = URL.createObjectURL(files[0]);
    onPropertyChanged("carouselUrls", urls);

    // With this change on state forces to refresh the image
    onPropertyChanged("refresh", undefined);
  }

  function carouselPreviousImage() {
    if (carouselIndex > 0) setCarouselIndex(carouselIndex - 1);
  }
  function carouselNextImage() {
    if (carouselIndex < carouselUrls.length - 1)
      setCarouselIndex(carouselIndex + 1);
  }
  function handleRemoveCarouselImage() {
    if (carouselUrls.length === 0) return;
    const arr = carouselUrls;
    arr.splice(carouselIndex, 1);

    let index = carouselIndex - 1;
    if (index < 0 && arr.length > 0) index = 0;

    onPropertyChanged("carouselUrls", arr);
    setCarouselIndex(index);

    // With this change on state forces to refresh the image
    onPropertyChanged("refresh", undefined);
  }

  return (
    <div id="carousel" className="panel">
      <p className="my-2">Carrusel</p>
      <div className="d-flex w-100 align-items-center">
        <Button disabled={carouselIndex < 1} onClick={carouselPreviousImage}>
          <FontAwesomeIcon icon={faChevronLeft} />
        </Button>
        <span className="flex-fill text-center ">
          {carouselUrls.length === 0 && <span>Sin imagenes</span>}
          {carouselUrls.length > 0 && (
            <span>
              Imagen {carouselIndex + 1} de {carouselUrls.length}
            </span>
          )}
        </span>
        <Button
          disabled={carouselIndex === carouselUrls.length - 1}
          onClick={carouselNextImage}
        >
          <FontAwesomeIcon icon={faChevronRight} />
        </Button>
      </div>

      <SelectImage
        imageUrl={
          carouselIndex >= 0 ? carouselUrls[carouselIndex] : "/img/no-image.png"
        }
        multiple
        buttonText="Agregar imagen"
        imageWidth="200px"
        imageHeight="366px"
        onImageChanged={handleAddCarouselImages}
      />

      <div className="d-flex flex-column mt-2">
        <div className="d-flex mt-1">
          <Button
            variant="outline-danger"
            size="lg"
            className="flex-fill"
            disabled={carouselUrls.length === 0}
            onClick={handleRemoveCarouselImage}
          >
            Quitar imagen
          </Button>
          <Button
            variant="secondary"
            size="lg"
            className="ms-1 flex-fill"
            disabled={carouselUrls.length === 0}
            onClick={() => {
              if (changeCarouselImageFileInput.current)
                changeCarouselImageFileInput.current.click();
            }}
          >
            Cambiar imagen
          </Button>
        </div>
      </div>

      <input
        type="file"
        title="Cambiar imagen"
        ref={changeCarouselImageFileInput}
        accept="image/png, image/gif, image/jpeg"
        style={{ display: "none" }}
        onChange={handleChangeCarouselImage}
      />
    </div>
  );
};

export default StoreSettingsCarousel;
