import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { SessionContextProvider } from "./api/context/SessionContext";
import { StoreContextProvider } from "./api/context/StoreContext";
import App from "./app";
import "./index.css";
import reportWebVitals from "./reportWebVitals";

import "./styles/scss/stylesheet.scss";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

root.render(
  <React.StrictMode>
    <div>
      <BrowserRouter>
        <SessionContextProvider>
          <StoreContextProvider>
            <Routes>
              <Route
                path=":tenantCode/*"
                element={
                  <div>
                    <App />
                  </div>
                }
              />
            </Routes>
          </StoreContextProvider>
        </SessionContextProvider>
      </BrowserRouter>
    </div>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
