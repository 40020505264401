import { Form } from "react-bootstrap";
import { LoadingButton } from "../../components/LoadingButton";
import { useSession } from "../../api/context/SessionContext";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useRef, useState } from "react";
import { Login } from "../../api/services/UsersServices";
import { GetErrorMessage } from "../../api/helpers/apiHelpers";
import React from "react";
import ErrorModal from "../../components/ErrorModal";

const LoginPage = () => {
    const session = useSession();
    const navigate = useNavigate();
    const [search, setSearch] = useSearchParams();

    const [eMail, setEmail] = useState("");
    const eMailInputRef = useRef<HTMLInputElement>(null);

    const [password, setPassword] = useState("");
    const passwordInputRef = useRef<HTMLInputElement>(null);

    const [loading, setLoading] = useState(false);
    const [showValidation, setShowValidation] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");

    const IniciarSesion = async () => {
        if (!ValidarCuenta()) return;

        try {
            setLoading(true);
            const response = await Login(eMail, password);
            if (!response.success) {
                setErrorMessage(response.message);
                return;
            }

            const loginResponse = session.logIn(response.token);
            if (!loginResponse) return;

            let returnPage = search.get("returnPage");
            if (!returnPage) returnPage = "#";
            navigate(returnPage);
        } catch (error) {
            setErrorMessage(GetErrorMessage(error));
            return;
        } finally {
            setLoading(false);
        }
    };

    const ValidarCuenta = () => {
        setShowValidation(true);
        if (!eMail) {
            (eMailInputRef.current as HTMLElement).focus();
            return false;
        }
        if (!password) {
            (passwordInputRef.current as HTMLElement).focus();
            return false;
        }

        setShowValidation(false);

        return true;
    };

    return (
        <div className="vh-100 d-flex flex-column align-items-center justify-content-center">
            <h1>Iniciar sesión</h1>
            <Form className="p-3 w-75">
                <Form.Group>
                    <Form.Label>Correo</Form.Label>
                    <Form.Control
                        type="email"
                        value={eMail}
                        onChange={(e) => setEmail(e.currentTarget.value)}
                    />
                </Form.Group>
                <Form.Group className="mt-3">
                    <Form.Label>Contraseña</Form.Label>
                    <Form.Control
                        type="password"
                        value={password}
                        onChange={(e) => setPassword(e.currentTarget.value)}
                    />
                </Form.Group>
                <div className="my-4 w-100 d-flex justify-content-center">
                    <a href="#">Olvidé mi contraseña</a>
                </div>

                <LoadingButton
                    isLoading={loading}
                    className="w-100 btn btn-lg btn-primary"
                    onClick={IniciarSesion}
                    loadingText=" "
                >
                    Iniciar sesión
                </LoadingButton>

                {errorMessage && (
                    <ErrorModal
                        errorMessage={errorMessage}
                        onOkClick={() => setErrorMessage("")}
                    />
                )}
            </Form>
        </div>
    );
};

export default LoginPage;
