import { Fragment } from "react";

import { faClose } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button } from "react-bootstrap";
import { NavLink, useNavigate } from "react-router-dom";
import { useStoreContext } from "../../api/context/StoreContext";
import { useSession } from "../../api/context/SessionContext";
import React from "react";
import Logo from "../Logo";

interface SideBarProps {
    visible: boolean;
    onCloseButton: () => void;
}

const SideBar = (props: SideBarProps) => {
    const { store } = useStoreContext();
    const session = useSession();
    const navigate = useNavigate();

    if (!props.visible) return <Fragment />;

    function logOff() {
        session.logOut();
        navigate(".");
    }

    return (
        <div className="sidebar">
            <Button
                variant="outline"
                className="sidebar-closebtn"
                onClick={props.onCloseButton}
            >
                <FontAwesomeIcon icon={faClose} />
            </Button>
            <Logo height="30px" />

            <div id="principal" className="sidebar-submenu">
                <NavLink to="." onClick={props.onCloseButton}>
                    Principal
                </NavLink>
            </div>

            <div id="pedidos" className="sidebar-submenu">
                <NavLink
                    className="sidebar-submenu--border-bottom"
                    to="orders"
                    onClick={props.onCloseButton}
                >
                    Pedidos
                </NavLink>
                <NavLink
                    className="sidebar-submenu--border-bottom"
                    to="products-ordered"
                    onClick={props.onCloseButton}
                >
                    Productos pedidos
                </NavLink>
                <NavLink
                    className="sidebar-submenu--border-bottom"
                    to="fulfill-orders"
                    onClick={props.onCloseButton}
                >
                    Surtir pedidos
                </NavLink>

                <NavLink
                    to="pack-orders"
                    className="sidebar-submenu--border-bottom"
                    onClick={props.onCloseButton}
                >
                    Empacar pedidos
                </NavLink>

                <NavLink
                    to="ship-orders"
                    className="sidebar-submenu--border-bottom"
                    onClick={props.onCloseButton}
                >
                    Enviar pedidos
                </NavLink>

                <NavLink
                    to="deliver-orders"
                    className="sidebar-submenu--border-bottom"
                    onClick={props.onCloseButton}
                >
                    Entregar pedidos
                </NavLink>

                <NavLink to="orders-receivable" onClick={props.onCloseButton}>
                    Pedidos por Cobrar
                </NavLink>
            </div>

            <div id="coupons" className="sidebar-submenu">
                <NavLink to="coupons" onClick={props.onCloseButton}>
                    Cupones de descuento
                </NavLink>
            </div>

            <div id="configuracion" className="sidebar-submenu">
                <NavLink
                    to="store/settings"
                    onClick={props.onCloseButton}
                    className="sidebar-submenu--border-bottom"
                >
                    Configuración de la tienda
                </NavLink>

                <NavLink
                    to="products/categories"
                    className="sidebar-submenu--border-bottom"
                    onClick={props.onCloseButton}
                >
                    Categorías
                </NavLink>
                <NavLink
                    className="sidebar-submenu--border-bottom"
                    to="products"
                    onClick={props.onCloseButton}
                >
                    Productos
                </NavLink>

                <NavLink
                    to="paymentmethods"
                    onClick={props.onCloseButton}
                    className="sidebar-submenu--border-bottom"
                >
                    Formas de pago
                </NavLink>

                <NavLink to="users" onClick={props.onCloseButton}>
                    Usuarios y permisos
                </NavLink>
            </div>

            <div id="logoff" className="sidebar-logoff">
                <Button onClick={logOff} variant="outline">
                    Cerrar sesión
                </Button>
            </div>
        </div>
    );
};

export default SideBar;
