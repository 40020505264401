import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useState } from "react";
import { Alert, Button, Form } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import {
    ProductCategory,
    ProductSubCategory,
} from "../../../api/models/productCategoryModel";
import {
    CreateProductCategory,
    DeleteProductCategory,
    GetProductCategory,
    UpdateProductCategory as APIUpdateProductCategory,
} from "../../../api/services/productCategoriesServices";
import SelectImage from "../../../components/SelectImage";
import Tabs from "../../../components/Tab";
import { eActionType } from "../../../api/models/common";
import { IError } from "../../../api/models/errorModel";
import TitleBar from "../../../components/TitleBar";
import LoadingComponent from "../../../components/LoadingComponent";
import ErrorModal from "../../../components/ErrorModal";
import RemoveImageBackground from "../../../components/RemoveImageBackground";
import UpdateProductSubCategory from "./UpdateProductSubCategory";

const UpdateProductCategory = () => {
    const params = useParams();

    const [error, setError] = useState<IError>();
    const [loadingError, setLoadingError] = useState<React.ReactNode>();

    const [isLoading, setIsLoading] = useState(false);
    const [loadingText, setLoadingText] = useState<string | undefined>();

    const [category, setCategory] = useState<ProductCategory>({
        id: 0,
        name: "",
        order: -1,
        imageUrl: "",
    });

    const [editSubCategoryAction, setEditSubCategoryAction] =
        useState<eActionType>(eActionType.None);
    const [editingSubCategory, setEditingSubCategory] =
        useState<ProductSubCategory>({ id: 0, name: "", order: 0 });

    const [selectedTab, setSelectedTab] = useState<number>(0);
    const [actionType, setActionType] = useState<eActionType>();

    const navigate = useNavigate();

    useEffect(() => {
        initialize();
    }, []);

    const initialize = async () => {
        if (params.categoryId) {
            try {
                setError(undefined);
                setLoadingError(undefined);
                setIsLoading(true);
                let categoryId = parseInt(params.categoryId);
                let category = await GetProductCategory(categoryId);
                if (category) setCategory(category);
                if (!category)
                    console.log("Error category not found", categoryId);
                setActionType(eActionType.Update);
            } catch (error) {
                let message = error;
                if (error instanceof Error) message = error.message;
                setLoadingError(
                    <p>
                        Ha ocurrido un error al cargar las categorias de
                        productos. <br /> Reintente más tarde.
                    </p>
                );
                console.log(message, error);
            } finally {
                setIsLoading(false);
            }
        } else {
            setCategory({ ...category, order: 0 });
            setActionType(eActionType.Create);
        }
    };

    const save = async () => {
        if (!validate()) return;

        try {
            setIsLoading(true);
            setLoadingText("Guardando...");
            setError(undefined);
            if (actionType === eActionType.Create)
                await CreateProductCategory(category);
            if (actionType === eActionType.Update)
                await APIUpdateProductCategory(category);

            navigate(-1);
        } catch (error) {
            let message = error;
            if (error instanceof Error) message = error.message;
            setError({ message: message as string });
        } finally {
            setIsLoading(false);
            setLoadingText(undefined);
        }
    };

    const validate = () => {
        if (!category.name) {
            setError({
                message: "Escriba el nombre de la categoría",
                property: "name",
            });
            return false;
        }

        setError(undefined);
        return true;
    };

    const handleDeleteCategory = async () => {
        if (!window.confirm("¿ Está seguro de eliminar la categoría ?")) return;

        try {
            setError(undefined);
            setIsLoading(true);
            setLoadingText("Eliminando...");

            await DeleteProductCategory(category);

            navigate(-1);
        } catch (error) {
            let message = error;
            if (error instanceof Error) message = error.message;
            setError({ message: message as string });
        } finally {
            setIsLoading(false);
            setLoadingText(undefined);
        }
    };

    const getNewSubCategory = () => {
        let order = 0;
        let id = 0;
        if (category.subCategories && category.subCategories.length > 0) {
            order =
                category.subCategories[category.subCategories.length - 1]
                    .order + 1;
            id =
                category.subCategories[category.subCategories.length - 1].id +
                1;
        }

        return { id, name: "", order };
    };

    const handleSaveSubCategory = (subCategory: ProductSubCategory) => {
        if (editSubCategoryAction === eActionType.Create) {
            if (!category.subCategories) category.subCategories = [];
            category.subCategories.push(subCategory);
        }

        if (editSubCategoryAction === eActionType.Update) {
            if (!category.subCategories) return;
            let index = category.subCategories.findIndex(
                (x) => x.id === subCategory.id
            );
            if (index < 0) {
                console.log("Error: SubCategory not found"); // TODO: show this error
                return;
            }
            category.subCategories[index] = subCategory;
        }

        setCategory({ ...category });
        setEditSubCategoryAction(eActionType.None);
    };

    const handleDeleteSubCategory = (subCategory: ProductSubCategory) => {
        if (!category.subCategories) return;
        let index = category.subCategories.findIndex(
            (x) => x.id === subCategory.id
        );
        if (index < 0) {
            console.log("Error: SubCategory not found"); // TODO: show this error
            return;
        }

        category.subCategories.splice(index, 1);
        setCategory({ ...category });
        setEditSubCategoryAction(eActionType.None);
    };

    if (loadingError)
        return (
            <ErrorModal
                errorMessage={loadingError}
                onRetryClick={initialize}
                onOkClick={() => navigate(-1)}
            />
        );

    return (
        <div id="updateProductCategory">
            <TitleBar
                title={
                    actionType === eActionType.Create
                        ? "Crear categoría"
                        : "Editar categoría"
                }
                backUrl="-1"
            />
            <Tabs
                tabs={["Categoría", "Sub-Categorías"]}
                selectedTabIndex={selectedTab}
                onSelectedTabChanged={(s) => setSelectedTab(s)}
            />
            <Form>
                {/* Category */}
                {selectedTab === 0 && (
                    <div id="category" className="panel">
                        <Form.Group id="name" className="formGroup">
                            <Form.Label>Nombre</Form.Label>
                            <Form.Control
                                type="text"
                                placeholder="Nombre de la categoría"
                                value={category.name}
                                isInvalid={error?.property === "name"}
                                onChange={(e) =>
                                    setCategory({
                                        ...category,
                                        name: e.currentTarget.value,
                                    })
                                }
                            />
                        </Form.Group>

                        <Form.Group id="image" className="formGroup">
                            <SelectImage
                                imageUrl={
                                    category.imageUrl
                                        ? category.imageUrl
                                        : "/img/no-image.png"
                                }
                                imageWidth="50px"
                                imageHeight="50px"
                                imageAlt="Imagen de la categoría"
                                onImageChanged={(imagesUrl) =>
                                    setCategory({
                                        ...category,
                                        imageUrl: imagesUrl[0],
                                    })
                                }
                            />{" "}
                            <RemoveImageBackground
                                sourceImageUrl={category.imageUrl}
                                onBackgroundRemoved={(url) =>
                                    setCategory({ ...category, imageUrl: url })
                                }
                                className="w-100 my-2"
                            />
                        </Form.Group>

                        {actionType === eActionType.Update && (
                            <Form.Group id="order" className="formGroup">
                                <Form.Label>Orden</Form.Label>
                                <Form.Control
                                    type="number"
                                    min="0"
                                    placeholder="Orden de despliegue"
                                    value={category.order}
                                    isInvalid={error?.property === "order"}
                                    onChange={(e) =>
                                        setCategory({
                                            ...category,
                                            order: parseInt(
                                                e.currentTarget.value
                                            ),
                                        })
                                    }
                                />
                            </Form.Group>
                        )}
                    </div>
                )}

                {/* subCategories */}
                {selectedTab === 1 && (
                    <div>
                        <div className="panel">
                            <div id="toolbar" className="toolbar">
                                <Button
                                    id="addCategory"
                                    variant="success"
                                    onClick={() => {
                                        setEditingSubCategory(
                                            getNewSubCategory
                                        );
                                        setEditSubCategoryAction(
                                            eActionType.Create
                                        );
                                    }}
                                >
                                    <FontAwesomeIcon icon={faPlus} />
                                </Button>
                            </div>
                            <div className="list">
                                {category.subCategories?.map((x, i) => (
                                    <div
                                        key={i}
                                        className="list-item"
                                        onClick={() => {
                                            setEditingSubCategory(x);
                                            setEditSubCategoryAction(
                                                eActionType.Update
                                            );
                                        }}
                                    >
                                        {x.name}
                                    </div>
                                ))}
                            </div>
                        </div>
                        {editSubCategoryAction !== eActionType.None && (
                            <UpdateProductSubCategory
                                actionType={editSubCategoryAction}
                                subCategory={editingSubCategory}
                                onHide={() =>
                                    setEditSubCategoryAction(eActionType.None)
                                }
                                onSave={handleSaveSubCategory}
                                onDelete={handleDeleteSubCategory}
                            />
                        )}
                    </div>
                )}
            </Form>
            {error && (
                <Alert className="mt-4 mb-2" variant="danger">
                    {error?.message}
                </Alert>
            )}
            <div className="bottom-toolbar">
                {actionType === eActionType.Update && (
                    <Button
                        size="lg"
                        variant="outline-danger"
                        onClick={handleDeleteCategory}
                    >
                        Eliminar
                    </Button>
                )}
                <Button size="lg" variant="success" onClick={save}>
                    Guardar
                </Button>
            </div>
            {isLoading && <LoadingComponent loadingText={loadingText} />}
        </div>
    );
};

export default UpdateProductCategory;
