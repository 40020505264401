import { useEffect, useState } from "react";
import { Order } from "../../../api/models/orderModel";
import { CalculateOrderBalance } from "../../../api/services/ordersServices";
import { FormatDecimal } from "../../../api/helpers/formatters";

interface Props {
  order?: Order;
  orderBalance?: number;
}
const OrderBalanceBadge = ({ order, orderBalance = 0 }: Props) => {
  const [balance, setBalance] = useState<number>(orderBalance);
  useEffect(() => {
    if (order) setBalance(CalculateOrderBalance(order));
  }, [order]);

  if (balance > 0)
    return (
      <span
        className="badge rounded-pill bg-danger"
        style={{ height: "1.4rem" }}
      >
        por Cobrar {FormatDecimal(balance, true)}
      </span>
    );

  return (
    <span
      className="badge rounded-pill bg-success"
      style={{ height: "1.4rem" }}
    >
      Pagada
    </span>
  );
};

export default OrderBalanceBadge;
