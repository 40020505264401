import { FormControl, FormGroup, FormLabel } from "react-bootstrap";
import { StoreContactInfo } from "../../api/models/storeModel";

interface Props {
  contactInfo: StoreContactInfo;
  onPropertyChanged: (propertyName: string, value: any) => void;
}

const StoreSettingsContact = ({ contactInfo, onPropertyChanged }: Props) => {
  return (
    <div id="contact" className="panel">
      <FormGroup className="formGroup">
        <FormLabel>Página web</FormLabel>
        <FormControl
          type="text"
          placeholder="URL de la página web"
          value={contactInfo.homePageUrl}
          onChange={(e) =>
            onPropertyChanged("contactInfo", {
              ...contactInfo,
              homePageUrl: e.currentTarget.value,
            })
          }
        />
      </FormGroup>
      <FormGroup className="formGroup">
        <FormLabel>eMail contacto</FormLabel>
        <FormControl
          type="text"
          placeholder="eMail para contacto"
          value={contactInfo.email}
          onChange={(e) =>
            onPropertyChanged("contactInfo", {
              ...contactInfo,
              email: e.currentTarget.value,
            })
          }
        />
      </FormGroup>
      <FormGroup className="formGroup">
        <FormLabel>Facebook</FormLabel>
        <FormControl
          type="text"
          placeholder="Liga para Facebook"
          value={contactInfo.facebook}
          onChange={(e) =>
            onPropertyChanged("contactInfo", {
              ...contactInfo,
              facebook: e.currentTarget.value,
            })
          }
        />
      </FormGroup>
      <FormGroup className="formGroup">
        <FormLabel>Instagram</FormLabel>
        <FormControl
          type="text"
          placeholder="Liga para Instagram"
          value={contactInfo.instagram}
          onChange={(e) =>
            onPropertyChanged("contactInfo", {
              ...contactInfo,
              instagram: e.currentTarget.value,
            })
          }
        />
      </FormGroup>
      <FormGroup className="formGroup">
        <FormLabel>WhatsApp</FormLabel>
        <FormControl
          type="text"
          placeholder="Número para contacto WhatsApp"
          value={contactInfo.whatsApp}
          onChange={(e) =>
            onPropertyChanged("contactInfo", {
              ...contactInfo,
              whatsApp: e.currentTarget.value,
            })
          }
        />
      </FormGroup>
    </div>
  );
};

export default StoreSettingsContact;
