import { useEffect, useState } from "react";
import TitleBar from "../../../components/TitleBar";
import {
    GetOrdersToPack,
    GetOrdersToPackViewModel,
} from "../../../api/services/ordersServices";
import Tabs from "../../../components/Tab";
import LabelGroup from "../../../components/LabelGroup";
import DeliveryTypeBadge from "../Components/DeliveryTypeBadge";
import { useNavigate } from "react-router-dom";
import LoadingComponent from "../../../components/LoadingComponent";
import ErrorModal from "../../../components/ErrorModal";
import React from "react";
import { GetErrorMessage } from "../../../api/helpers/apiHelpers";
import { useSession } from "../../../api/context/SessionContext";

const PackOrdersPage = () => {
    const navigate = useNavigate();
    const session = useSession();

    const [orders, setOrders] = useState<GetOrdersToPackViewModel[]>([]);
    const [filterDeliveryType, setFilterDeliveryType] = useState<number>(0);

    const [isLoading, setIsLoading] = useState(true);
    const [errorMessage, setErrorMessage] = useState<React.ReactNode>();

    useEffect(() => {
        // Valida que tenga acceso a esta opción
        if (
            !session.isLoggedIn() ||
            !session.hasDoor("9167122e-cf3d-4cfd-abb3-be9aee4f53b8")
        ) {
            alert("No tiene permiso para ingresar a esta sección");
            navigate("../");
            return;
        }

        fetchData();
    }, []);

    const fetchData = async () => {
        try {
            setIsLoading(true);
            setErrorMessage(undefined);
            const response = await GetOrdersToPack();
            if (!response.success) {
                setErrorMessage(response.message);
                return;
            }

            setOrders(response.orders);
        } catch (error) {
            setErrorMessage(GetErrorMessage(error));
        } finally {
            setIsLoading(false);
        }
    };

    const filterOrders = () => {
        if (filterDeliveryType === 0) return orders;

        return orders.filter((x) => x.deliveryType === filterDeliveryType - 1);
    };

    if (isLoading) return <LoadingComponent />;

    if (errorMessage)
        return (
            <ErrorModal
                errorMessage={errorMessage}
                onRetryClick={fetchData}
                onOkClick={() => setErrorMessage(undefined)}
            />
        );

    return (
        <div>
            <TitleBar title="Empacar pedidos" />

            <Tabs
                tabs={["Todos", "Tienda", "Domicilio"]}
                selectedTabIndex={filterDeliveryType}
                onSelectedTabChanged={(e) => setFilterDeliveryType(e)}
            />

            <div id="list">
                {filterOrders().map((x, i) => (
                    <div key={i} className="panel">
                        <div className="d-flex justify-content-between">
                            <LabelGroup caption="Pedido">
                                {x.id.toString()}
                            </LabelGroup>
                            <LabelGroup caption="Fecha entrega">
                                {x.promisedDeliveryDate}
                            </LabelGroup>
                        </div>
                        <div>{x.customerName}</div>
                        <div className="mt-1 d-flex justify-content-between align-items-center">
                            <DeliveryTypeBadge deliveryType={x.deliveryType} />
                            <span>{x.itemsCount} productos</span>
                        </div>
                        <button
                            className="mt-2 btn btn-success"
                            onClick={() => navigate(`../pack-order/${x.id}`)}
                        >
                            Empacar
                        </button>
                    </div>
                ))}
                {filterOrders().length === 0 && (
                    <div className="d-flex flex-column justify-content-center align-items-center">
                        No hay pedidos por empacar
                    </div>
                )}
                <div
                    id="totals"
                    className="d-flex justify-content-between mt-4 mb-4"
                >
                    <LabelGroup caption="No. Pedidos">
                        {filterOrders().length}
                    </LabelGroup>
                </div>
            </div>
        </div>
    );
};

export default PackOrdersPage;
