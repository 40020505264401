import {
  Alert,
  Button,
  FormControl,
  FormGroup,
  FormLabel,
  Modal,
} from "react-bootstrap";
import { eShippingType } from "../../../api/models/orderModel";
import { useState } from "react";
import OrderBalanceBadge from "../Components/OrderBalanceBadge";
import Tabs from "../../../components/Tab";
import { GetOrdersToShipViewModel } from "../../../api/services/ordersServices";

interface Props {
  order: GetOrdersToShipViewModel;
  onClose: () => void;
  onShipped: (
    orderId: number,
    shippingType: eShippingType,
    shippingService: string,
    shippingTrackingGuide?: string
  ) => Promise<void>;
}
const ShipOrder = ({ order, onClose, onShipped }: Props) => {
  const [errorText, setErrorText] = useState("");
  const [shippingType, setShippingType] = useState<eShippingType>(
    eShippingType.Own
  );
  const [shippingService, setShippingService] = useState("");
  const [shippingTrackingGuide, setShippingTrackingGuide] = useState("");

  function handleEnviar() {
    setErrorText("");
    if (!shippingService) {
      if (shippingType === eShippingType.Own)
        setErrorText("Indique el nombre del chofer con quien se envía");
      else
        setErrorText("Indique el nombre de la paquetería con quien se envía");
      return;
    }

    onShipped(order.id, shippingType, shippingService, shippingTrackingGuide);
  }

  return (
    <Modal show={true} onHide={onClose}>
      <Modal.Header closeButton>
        <Modal.Title>Enviar pedido {order.id}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div>
          <OrderBalanceBadge orderBalance={order.balance} />
        </div>
        <Tabs
          selectedTabIndex={shippingType}
          tabs={["Propia", "Paquetería"]}
          onSelectedTabChanged={(index) => setShippingType(index)}
        />
        <FormGroup className="mt-2">
          <FormLabel>
            {shippingType === eShippingType.Own ? "Chofer" : "Paquetería"}
          </FormLabel>
          <FormControl
            type="text"
            value={shippingService}
            onChange={(e) => setShippingService(e.currentTarget.value)}
          />
        </FormGroup>
        {shippingType === eShippingType.ThirdParty && (
          <FormGroup className="mt-2">
            <FormLabel>Guía</FormLabel>
            <FormControl
              type="text"
              value={shippingTrackingGuide}
              onChange={(e) => setShippingTrackingGuide(e.currentTarget.value)}
            />
          </FormGroup>
        )}
      </Modal.Body>
      <Modal.Footer>
        {errorText && (
          <Alert className="mt-4 mb-2 w-100" variant="danger">
            {errorText}
          </Alert>
        )}
        <Button variant="success" onClick={handleEnviar}>
          Enviar
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ShipOrder;
