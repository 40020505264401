import { LoadingButton } from "./LoadingButton";
import { useState } from "react";

interface Props {
  sourceImageUrl: string;
  className?: string;
  onBackgroundRemoved: (imageUrl: string) => void;
}

const RemoveImageBackground = ({
  sourceImageUrl,
  onBackgroundRemoved,
  className,
}: Props) => {
  const [loading, setLoading] = useState(false);

  const handleClick = async () => {
    try {
      setLoading(true);

      const formData = await getFormDataFromFileUrl(sourceImageUrl);

      const response = await fetch("https://sdk.photoroom.com/v1/segment", {
        method: "POST",
        headers: {
          "x-api-key": process.env.REACT_APP_PHOTOROOM_API_KEY!,
        },
        body: formData,
      });

      if (response.status !== 200) {
        let errorMessage = "";
        if (response.status === 402)
          errorMessage =
            "No tiene créditos suficientes para quitar el fondo de la imagen";
        else errorMessage = "Error al quitar el fondo de la imagen";
        console.log("Remove background response: ", response);
        alert(errorMessage);
        return;
      }

      const blob = await response.blob();

      const fileName = URL.createObjectURL(blob);

      onBackgroundRemoved(fileName);
    } catch (error) {
      alert("Error al quitar el fondo de la imagen");
    } finally {
      setLoading(false);
    }
  };

  async function getFormDataFromFileUrl(fileUrl: string): Promise<FormData> {
    const response = await fetch(fileUrl);
    const blob = await response.blob();

    const formData = new FormData();
    formData.append("image_file", blob, "file.jpg");

    //console.log(Array.from(formData.entries()));

    return formData;
  }

  return (
    <LoadingButton
      isLoading={loading}
      variant="primary"
      className={className}
      onClick={handleClick}
    >
      Quitar fondo
    </LoadingButton>
  );
};

export default RemoveImageBackground;
