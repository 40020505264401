import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { ProductCategory } from "../../../api/models/productCategoryModel";
import { GetProductCategories } from "../../../api/services/productCategoriesServices";
import SearchEntry from "../../../components/SearchEntry";
import TitleBar from "../../../components/TitleBar";
import LoadingComponent from "../../../components/LoadingComponent";
import ErrorModal from "../../../components/ErrorModal";
import { useSession } from "../../../api/context/SessionContext";

const ProductsCategoriesPage = () => {
    const navigate = useNavigate();
    const session = useSession();

    const [categories, setCategories] = useState<ProductCategory[]>();

    const [isLoading, setIsLoading] = useState(true);
    const [loadingError, setLoadingError] = useState<React.ReactNode>();

    const [searchText, setSearchText] = useState<string | undefined>();

    useEffect(() => {
        // Valida que tenga acceso a esta opción
        if (
            !session.isLoggedIn() ||
            !session.hasDoor("ee100bec-404a-44a8-bd8e-9eaa65f1628c")
        ) {
            alert("No tiene permiso para ingresar a esta sección");
            navigate("../");
        }

        fetchData();
    }, []);

    const fetchData = async () => {
        try {
            setIsLoading(true);
            setLoadingError(undefined);

            let result = await GetProductCategories();
            setCategories(result);
        } catch (error) {
            let message = error;
            if (error instanceof Error) message = error.message;
            setLoadingError(
                <p>
                    Ha ocurrido un error al cargar los datos. <br /> Reintente
                    más tarde.
                </p>
            );

            console.log(message, error);
        } finally {
            setIsLoading(false);
        }
    };

    const getCategories = () => {
        if (!categories) return [];

        let ds = [...categories];

        if (searchText) {
            let searchTextLower = searchText.toLocaleLowerCase();
            ds = ds.filter((x) =>
                x.name.toLocaleLowerCase().includes(searchTextLower)
            );
        }

        return ds.sort((a, b) =>
            a.order < b.order ? -1 : a.order > b.order ? 1 : 0
        );
    };

    if (isLoading) return <LoadingComponent />;

    if (loadingError)
        return (
            <ErrorModal
                errorMessage={loadingError}
                onRetryClick={fetchData}
                onOkClick={() => setLoadingError(undefined)}
            />
        );

    return (
        <div>
            <TitleBar title="Categorías" />

            <SearchEntry onChange={(e) => setSearchText(e)} />

            <div id="toolbar" className="toolbar">
                <Button
                    id="addCategory"
                    variant="success"
                    onClick={() => navigate("../products/categories-add")}
                >
                    <FontAwesomeIcon icon={faPlus} />
                </Button>
            </div>

            <div id="categoriesList">
                {getCategories().map((x, i) => (
                    <div
                        className="panel"
                        key={i}
                        onClick={() =>
                            navigate(`../products/categories/${x.id}`)
                        }
                    >
                        <div className="row">
                            <div className="col-2">
                                <img
                                    style={{ maxWidth: "48px" }}
                                    src={x.imageUrl}
                                    alt=""
                                />
                            </div>
                            <div className="col d-flex align-items-center">
                                {x.name}
                            </div>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default ProductsCategoriesPage;
