import { useEffect, useState } from "react";
import { OrderItem } from "../../../api/models/orderModel";
import { Alert, Button, Form, Modal } from "react-bootstrap";
import { FormatDecimal } from "../../../api/helpers/formatters";
import { CalculateOrderItem } from "../../../api/services/ordersServices";
import LookUpProduct from "../../Products/LookupProducto";
import NumericInput from "../../../components/NumericInput";
import { IError } from "../../../api/models/errorModel";
import React from "react";

interface Props {
    item?: OrderItem;
    onComplete: (item: OrderItem) => void;
    onDelete: (item: OrderItem) => void;
    onHide: () => void;
}

const EditOrderItem = (props: Props) => {
    const [item, setItem] = useState<OrderItem>();
    const [error, setError] = useState<IError>();

    const [lookUpProductoVisible, setLookUpProductoVisible] =
        useState<boolean>(false);

    useEffect(() => {
        if (props.item) setItem(props.item);
    }, [props.item]);

    if (!item)
        return (
            <LookUpProduct
                onClose={props.onHide}
                onSelected={(p) => {
                    const i: OrderItem = {
                        product: p,
                        id: 0,
                        quantity: 0,
                        price: p.price,
                        amount: 0,
                        quantityFulfilled: 0,
                    };
                    setItem(i);
                }}
            />
        );

    function handleQuantityChanged(quantity: number) {
        if (quantity < item!.quantityFulfilled) {
            alert(
                "La cantidad solicitada no puede ser menor a la cantidad surtida"
            );
            return;
        }

        item!.quantity = quantity;

        CalculateOrderItem(item!);

        setItem({ ...item! });
    }

    if (lookUpProductoVisible)
        return (
            <LookUpProduct
                onClose={() => setLookUpProductoVisible(false)}
                onSelected={(p) => console.log("selected product", p)}
            />
        );

    function handleSave() {
        if (!validate()) return;

        setItem({ ...item! });
        props.onComplete(item!);
    }

    function validate() {
        if (!item?.product) {
            setError({
                message: "Seleccione el producto",
                property: "product",
            });
            return false;
        }

        if (item!.quantity <= 0) {
            setError({
                message: "Indique la cantidad del producto",
                property: "quantity",
            });
            return false;
        }

        setError(undefined);
        return true;
    }

    return (
        <Modal show={true} onHide={props.onHide}>
            <Modal.Header closeButton>
                <Modal.Title>
                    {item.id === 0 ? "Agregar partida" : "Editar partida"}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form>
                    <div
                        className="d-flex"
                        onClick={() => setLookUpProductoVisible(true)}
                    >
                        <img
                            style={{ maxWidth: "64px", maxHeight: "64px" }}
                            src={item.product.thumbUrl}
                            alt={item.product.name}
                        />
                        <div className="flex-grow-1 ps-2">
                            <div>{item.product.name}</div>
                            <span>Precio: </span>
                            <strong>
                                {FormatDecimal(item.price, true)} /{" "}
                                {item.product.unitOfMeasure}
                            </strong>
                        </div>
                    </div>
                    <Form.Group className="mt-2">
                        <Form.Label>Cantidad solicitada:</Form.Label>
                        <NumericInput
                            type="number"
                            value={item.quantity === 0 ? "" : item.quantity}
                            isInvalid={error?.property === "quantity"}
                            onValueChanged={(e) => handleQuantityChanged(e)}
                        />
                    </Form.Group>
                    <div className="mt-2">
                        Importe:{" "}
                        <strong>{FormatDecimal(item.amount, true)}</strong>
                    </div>

                    {item.quantityFulfilled > 0 && (
                        <div className="mt-2">
                            Cantidad surtida:{" "}
                            <strong>
                                {FormatDecimal(item.quantityFulfilled, false)}{" "}
                                {item.product.unitOfMeasure}
                            </strong>
                        </div>
                    )}
                    <Form.Group className="mt-2">
                        <Form.Label>Notas:</Form.Label>
                        <Form.Control
                            as={"textarea"}
                            rows={3}
                            value={item.notes}
                            onChange={(e) => {
                                item.notes = e.currentTarget.value;
                                setItem({ ...item });
                            }}
                        />
                    </Form.Group>
                </Form>
            </Modal.Body>
            <Modal.Footer className="justify-content-between">
                {error && (
                    <Alert className="mt-4 mb-2 w-100" variant="danger">
                        {error.message}
                    </Alert>
                )}
                <Button
                    size="lg"
                    variant="outline-danger"
                    onClick={() => props.onDelete(item)}
                >
                    Eliminar
                </Button>
                <Button variant="success" onClick={handleSave}>
                    Guardar
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default EditOrderItem;
