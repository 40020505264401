import { useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { eActionType } from "../../api/models/common";
import { Alert, Button, Form, Modal } from "react-bootstrap";
import { PaymentMethod } from "../../api/models/paymentMethodModel";
import {
    AddPaymentMethod,
    UpdatePaymentMethod as APIPaymentMethod,
    DeletePaymentMethod,
    GetPaymentMethod,
} from "../../api/services/paymentMethodServices";
import { GetErrorMessage } from "../../api/helpers/apiHelpers";
import ErrorModal from "../../components/ErrorModal";
import LoadingComponent from "../../components/LoadingComponent";

const UpdatePaymentMethod = () => {
    const params = useParams();
    const navigate = useNavigate();

    const [actionType, setActionType] = useState<eActionType>(eActionType.None);
    const [paymentMethod, setPaymentMethod] = useState<PaymentMethod>({
        id: 0,
        name: "",
        referenceRequired: false,
        referenceName: "",
    });
    const [errorText, setErrorText] = useState<string>("");

    const [isLoading, setIsLoading] = useState(true);
    const [errorMessage, setErrorMessage] = useState<React.ReactNode>();

    const initializing = useRef(false); // Esto es para evitar que se ejecute el useEffect dos veces

    useEffect(() => {
        if (!initializing.current) fetchData();
    }, [initializing]);

    const fetchData = async () => {
        initializing.current = true;

        if (!params.paymentMethodId) {
            setActionType(eActionType.Create);
            setIsLoading(false);
            return;
        }

        try {
            setIsLoading(true);
            setErrorMessage(undefined);
            const response = await GetPaymentMethod(
                parseInt(params.paymentMethodId)
            );
            if (!response.success) {
                setErrorMessage(response.message);
                return;
            }

            setPaymentMethod(response.paymentMethod);
            setActionType(eActionType.Update);
        } catch (error) {
            setErrorMessage(GetErrorMessage(error));
        } finally {
            setIsLoading(false);
        }
    };

    const handleGuardar = async () => {
        if (!validar()) return;

        try {
            setIsLoading(true);
            setErrorMessage(undefined);

            if (actionType === eActionType.Create) {
                const response = await AddPaymentMethod(paymentMethod);
                if (!response.success) {
                    setErrorMessage(response.message);
                    return;
                }
            }

            if (actionType === eActionType.Update) {
                const response = await APIPaymentMethod(paymentMethod);
                if (!response.success) {
                    setErrorMessage(response.message);
                    return;
                }
            }
        } catch (error) {
            setErrorMessage(GetErrorMessage(error));
            return false;
        } finally {
            setIsLoading(false);
        }

        navigate(-1);
    };

    const validar = (): boolean => {
        setErrorText("");
        if (!paymentMethod.name) {
            setErrorText("Escriba el nombre de la forma de pago");
            return false;
        }

        if (paymentMethod.referenceRequired && !paymentMethod.referenceName) {
            setErrorText(
                "Indique el nombre de la referencia a solicitar para la forma de pago"
            );
            return false;
        }

        return true;
    };

    const handleEliminar = async () => {
        setErrorText("");
        if (
            !window.confirm(
                "¿ Está seguro que desea eliminar la forma de pago?"
            )
        )
            return;

        try {
            setIsLoading(true);
            setErrorMessage(undefined);

            const response = await DeletePaymentMethod(paymentMethod);
            if (!response.success) {
                setErrorMessage(response.message);
                return;
            }
            navigate(-1);
        } catch (error) {
            setErrorMessage(GetErrorMessage(error));
            return false;
        } finally {
            setIsLoading(false);
        }
    };

    if (errorMessage)
        return (
            <ErrorModal
                errorMessage={errorMessage}
                onRetryClick={!paymentMethod ? fetchData : undefined}
                onOkClick={() => {
                    if (!paymentMethod) navigate(-1);
                    else setErrorMessage(undefined);
                }}
            />
        );

    if (isLoading || !paymentMethod) return <LoadingComponent />;

    return (
        <div
            className="modal show"
            style={{ display: "block", position: "initial" }}
        >
            <Modal.Dialog>
                <Modal.Header closeButton onHide={() => navigate(-1)}>
                    <Modal.Title>
                        {actionType === eActionType.Create && (
                            <span>Nueva </span>
                        )}
                        {actionType === eActionType.Update && (
                            <span>Editar </span>
                        )}
                        forma de pago
                    </Modal.Title>
                </Modal.Header>

                <Modal.Body>
                    <Form>
                        <Form.Group>
                            <Form.Label>Nombre</Form.Label>
                            <Form.Control
                                type="text"
                                placeholder="Ej. Tarjeta de crédito"
                                value={paymentMethod.name}
                                onChange={(e) =>
                                    setPaymentMethod({
                                        ...paymentMethod,
                                        name: e.currentTarget.value,
                                    })
                                }
                            />
                        </Form.Group>
                        <Form.Group className="mt-2">
                            <Form.Check
                                type="checkbox"
                                label="Requiere referencia"
                                checked={paymentMethod.referenceRequired}
                                onChange={(e) =>
                                    setPaymentMethod({
                                        ...paymentMethod,
                                        referenceRequired:
                                            !paymentMethod.referenceRequired,
                                    })
                                }
                            />
                        </Form.Group>
                        {paymentMethod.referenceRequired && (
                            <Form.Group className="mt-2">
                                <Form.Label>Referencia</Form.Label>
                                <Form.Control
                                    type="text"
                                    placeholder="Ej. No. de tarjeta"
                                    value={paymentMethod.referenceName!}
                                    onChange={(e) =>
                                        setPaymentMethod({
                                            ...paymentMethod,
                                            referenceName:
                                                e.currentTarget.value,
                                        })
                                    }
                                />
                            </Form.Group>
                        )}
                    </Form>
                </Modal.Body>

                <Modal.Footer>
                    {errorText && (
                        <Alert className="mt-4 mb-2" variant="danger">
                            {errorText}
                        </Alert>
                    )}
                    <div className="bottom-toolbar">
                        {actionType === eActionType.Update && (
                            <Button
                                size="lg"
                                variant="outline-danger"
                                onClick={handleEliminar}
                            >
                                Eliminar
                            </Button>
                        )}
                        <Button variant="success" onClick={handleGuardar}>
                            Guardar
                        </Button>
                    </div>
                </Modal.Footer>
            </Modal.Dialog>
        </div>
    );
};

export default UpdatePaymentMethod;
